import React, { useEffect, useContext } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Footer } from '../../layouts/footer'
import { getSBDAppMan, playSound, getDeviceManager, history, getBuildAccessibility } from 'main'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { TestButtons } from 'components'
import { appLog } from 'utils/Logger'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import { turnLights } from 'utils/lights'
import { ThemeContext } from 'styled-components'
import { useIntl } from 'react-intl'
import { PageTitle, PageSubTitle, PageHeader, PageSubContent, DynamicImage } from 'components/styledComponents'
import { getScreenId } from 'utils/helper'
import { END_TXN_REASON } from '../../constants/Constants'
import useMedia from 'hooks/useMedia'
import useUIBase from 'hooks/ui/useUIBase'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'

const AgentScanBio = (props) => {
  const intl = useIntl()
  const buildAccessibility = getBuildAccessibility()
  const locale = useSelector((state) => state.localData.locale)
  const themeContext = useContext(ThemeContext)
  const dispatch = useDispatch()
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const errorDetails = useSelector((state) => state.errorDetails)
  const sbdScanExtend = useSelector((state) => state.settings.sbdScanExtend)
  let SBDAppManager = getSBDAppMan()

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_TRACE,
      '(AgentScanBio.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()
    switch (e.currentTarget.id) {
      case 'confirmBtn':
        SBDAppManager.doQuit(END_TXN_REASON.USER_QUIT, '')
        break
    }
  }

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])

  const imageLocation = `generic-agent.svg`
  const animationSection = (
    <DynamicImage imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
  )
  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        justifyContent="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {intl.formatMessage(messages.AgentScanBioTitle)}
      </PageTitle>
      <PageSubTitle
        justifyContent="center"
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.AgentScanBioSubTitle, {
          status: sbdScanExtend.split(' ')[1] === 'true' ? 'off' : 'on'
        })}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={true}
        confirmAction={handleActions}
        confirmBtnText={intl.formatMessage(messages.buttonConfirm)}
        isLangRequired={true}
        isHelpRequired={false}
      />
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default AgentScanBio
