/************************************************************/
/*                    Screen types                     */
/************************************************************/
export const SCREEN_TYPES = [
  '(min-width: 1281px) and (orientation: landscape)', //landscape1920
  '(min-width: 1025px) and (max-width: 1919px) and (orientation: landscape)', //landscape1280
  '(min-width: 769px) and (orientation: portrait)', //portrait1080
  '(max-width: 768px) and (orientation: portrait)' //portrait768
]
/************************************************************/
/*                    ETS Service Names                     */
/************************************************************/
export const ServiceNames = {
  WELCOME: 'WELCOME',
  IDENTIFY_PNR: 'IDENTIFY_PNR',
  CONFIRM_FLIGHTS: 'CONFIRM_FLIGHTS',
  SELECT_PASSENGERS: 'SELECT_PASSENGERS',
  UPDATE_APIS: 'UPDATE_APIS',
  UPDATE_FQTV: 'UPDATE_FQTV',
  CHECKIN_PASSENGER: 'CHECKIN_PASSENGER',
  CHECKIN_BAGGAGE: 'CHECKIN_BAGGAGE',
  CONFIRM_ITINERARY: 'CONFIRM_ITINERARY',
  CHANGE_SEAT: 'CHANGE_SEAT',
  ADD_BAGGAGE: 'ADD_BAGGAGE',
  SECURITY_QUESTIONS: 'SECURITY_QUESTIONS',
  DANGEROUS_GOODS_QUESTIONS: 'DANGEROUS_GOODS_QUESTIONS',
  ADD_PAYMENT: 'ADD_PAYMENT',
  PRINT_DOCUMENT: 'PRINT_DOCUMENT',
  //new services
  SBD_SCAN: 'SBD_SCAN',
  SBD_CONFIRM_WEIGHT_LIMIT: 'SBD_CONFIRM_WEIGHT_LIMIT',
  SBD_PAX_VALIDATION: 'SBD_PAX_VALIDATION',
  AGENT_CHECK: 'AGENT_CHECK',
  SBD_PROCESS: 'SBD_PROCESS',
  PRINT_BAGTAG: 'PRINT_BAGTAG',
  CUSTOM_4: 'CUSTOM_4'
}

/************************************************************/
/*  ETS configureation for initial transaction              */
/************************************************************/
export const INITIAL_TRANSACTIONS = {
  FACE_TRACKING: 'FACE_TRACKING',
  BAGTAG_LOOKUP: 'BAGTAG_LOOKUP',
  BP_SCAN: 'BP_SCAN'
}

/************************************************************/
/*                    ETS Transition Codes                  */
/************************************************************/
export const TransitionCodes = {
  WELCOME: 'WELCOME',
  PUT_BAG_ON_BELT: 'PUT_BAG_ON_BELT',
  PRINT_RECEIPTS: 'PRINT_RECEIPTS',
  BARCODE_RETRY: 'BARCODE_RETRY',
  BARCODE_RETRIES_EXCEEDED: 'BARCODE_RETRIES_EXCEEDED',
  FINISH: 'FINISH',
  END: 'END',
  END_TXN: 'END_TXN',
  SYNC_TSD: 'SYNC_TSD',
  OOS_ERROR: 'OOS_ERROR',
  SCAN_DOCUMENT: 'SCAN_DOCUMENT',
  TAKE_PHOTO: 'TAKE_PHOTO',
  PHOTO_NOT_REQUIRED: 'PHOTO_NOT_REQUIRED',
  NEXT_TRAVELER_PHOTO: 'NEXT_TRAVELER_PHOTO',
  SCAN_BP: 'SCAN_BP',
  ERROR_MISMATCH: 'ERROR_MISMATCH',
  PRINT_DOCUMENT: 'PRINT_DOCUMENT',
  //new transition code
  PAX_VALIDATION: 'PAX_VALIDATION',
  SBD_PROCESS: 'SBD_PROCESS',
  PASSENGER_SELECTION: 'PASSENGER_SELECTION',
  GET_BAG_WEIGHT_LIMIT: 'GET_BAG_WEIGHT_LIMIT',
  SHOW_BAG_WEIGHT_LIMIT: 'SHOW_BAG_WEIGHT_LIMIT',
  GET_BAGTAG_PRINTSTREAM: 'GET_BAGTAG_PRINTSTREAM',
  GET_BAGGAGE_DETAILS: 'GET_BAGGAGE_DETAILS',
  CUSTOM_4: 'CUSTOM_4',
  CUSTOM_9: 'CUSTOM_9',
  CUSTOM_10: 'CUSTOM_10',
  ADD_BAGGAGE: 'ADD_BAGGAGE',
  PRINT_DOCUMENT: 'PRINT_DOCUMENT',
  PAX_STATUS_INFO: 'PAX_STATUS_INFO',
  CHECKIN_BAGGAGE: 'CHECKIN_BAGGAGE',
  HEAVY_TAG: 'HEAVY_TAG',
  NOT_VALID: 'NOT_VALID',
  AGENT_CHECK: 'AGENT_CHECK',
  OVERWEIGHT_BAGGAGE: 'OVERWEIGHT_BAGGAGE',
  DANGEROUS_GOODS: 'DANGEROUS_GOODS',
  DANGEROUS_GOODS_QUESTIONS: 'DANGEROUS_GOODS_QUESTIONS',
  NONE: 'NONE',
  FACE_RECOGNITION: 'FACE_RECOGNITION',
  CHECK_VISA_REQUIRED: 'CHECK_VISA_REQUIRED',
  VALIDATE_AGENT: 'VALIDATE_AGENT',
  APIS_GET_VISA: 'APIS_GET_VISA',
  GET_SHOPPING_CART: 'GET_SHOPPING_CART',
  PAYMENT_SUMMARY: 'PAYMENT_SUMMARY',
  PAYMENT_RECEIPT: 'PAYMENT_RECEIPT',
  GET_SPECIAL_SVCS: 'GET_SPECIAL_SVCS',
  GET_SBD_RECEIPT: 'GET_SBD_RECEIPT',
  QUESTION_FOR_NEXT_BAG: 'QUESTION_FOR_NEXT_BAG',
  ATTACH_HEAVY_TAG: 'ATTACH_HEAVY_TAG',
  ADD_APIS_V_2: 'ADD_APIS_V_2'
}

/************************************************************/
/*                       TSD EVENT                          */
/************************************************************/

export const EventTypeName = {
  ENVIRONMENT_DETAILS: 'ENVIRONMENT_DETAILS',
  SESSION_SUMMARY_DETAILS: 'SESSION_SUMMARY_DETAILS',
  SCREEN_INTERACTION_EVENT_DETAILS: 'SCREEN_INTERACTION_EVENT_DETAILS',
  SCREEN_FLOW_EVENT_DETAILS: 'SCREEN_FLOW_EVENT_DETAILS',
  DEVICE_INTERACTION_EVENT_DETAILS: 'DEVICE_INTERACTION_EVENT_DETAILS',
  APP_STATE_EVENT_DETAILS: 'APP_STATE_EVENT_DETAILS',
  HOST_INTERFACE_EVENT_DETAILS: 'HOST_INTERFACE_EVENT_DETAILS',
  BUSINESS_FLOW_EVENT_DETAILS: 'BUSINESS_FLOW_EVENT_DETAILS'
}

/************************************************************/
/*                    ETS Function Codes                    */
/************************************************************/
export const FunctionCodes = {
  BYPASSED: 'BYPASSED',
  COMPLETED: 'COMPLETED',
  NEXT: 'NEXT',
  REMAINING: 'REMAINING',
  SET_COMPLETE: 'SET_COMPLETE',
  SET_SKIP: 'SET_SKIP'
}

/************************************************************/
/*                    Status Codes                          */
/************************************************************/
export const StatusCodes = {
  QUIT: 'QUIT',
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
  TIMEOUT: 'TIMEOUT',
  ERROR: 'ERROR'
}

/************************************************************/
/**                End Transaction Reasons                 **/
/************************************************************/
export const END_TXN_REASON = {
  COMPLETE_OK: 'COMPLETE_OK',
  USER_QUIT: 'USER_QUIT',
  TIMEOUT: 'TIMEOUT',
  ERROR: 'ERROR',
  LOCKOUT: 'LOCKOUT',
  MEDIA_ACCESS_RETRY: 'MEDIA_ACCESS_RETRY',
  TRANSFER: 'TRANSFER'
}

/************************************************************/
/**                ETS Transaction status                   **/
/************************************************************/
export const ETS_TXN_STATUS = {
  ETS_TXN_NONE: 0,
  ETS_TXN_STARTED: 1,
  ETS_TXN_OK: 2,
  ETS_TXN_FAILED: 3
}

/************************************************************/
/**                ETS defined SBD Update Action           **/
/************************************************************/
export const SBDUpdateAction = {
  VERIFY: 'VERIFY',
  ACCEPTED: 'ACCEPTED',
  INGESTED: 'INGESTED',
  ABANDONED: 'ABANDONED',
  NO_MORE_BAG: 'NO_MORE_BAG',
  CRITICAL: 'CRITICAL'
}

export const PassengerValidationTransitions = {
  START: 'START',
  PASSPORT_SCAN: 'PASSPORT_SCAN',
  CHECK_VISA: 'CHECK_VISA',
  FACE_RECOGNITION: 'FACE_RECOGNITION'
}

export const checkTravelDocAction = {
  CHECK_VISA_REQUIRED: 'CHECK_VISA_REQUIRED',
  CHECK_DOCINFO_ONLY: 'CHECK_DOCINFO_ONLY'
}

/************************************************************/
/**                ETS defined SBD Baggage Status          **/
/************************************************************/
export const BaggageStatus = {
  ADDED: 'ADDED',
  ACCEPTED: 'ACCEPTED',
  ACTIVATED: 'ACTIVATED'
}

/************************************************************/
/**                EKC APIS Transitions                    **/
/************************************************************/
export const APISTransitions = {
  START: 'START',
  CONFIRM_DOCUMENT: 'CONFIRM_DOCUMENT',
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  ADD_RESIDENCY: 'ADD_RESIDENCY',
  ADD_DESTINATION: 'ADD_DESTINATION',
  ADD_EMERGENCY_CONTACT: 'ADD_EMERGENCY_CONTACT',
  ADD_REDRESS: 'ADD_REDRESS',
  ADD_MEDIA_DATA: 'ADD_MEDIA_DATA'
}

/************************************************************/
/**                EKC Store Update Actions                **/
/************************************************************/
//common data actions
export const CommonActions = {
  ERROR: 'ERROR'
}

// local actions
export const Actions = {
  ADD_BAGGAGE: 'ADD_BAGGAGE',
  CLEAR_DATA: 'CLEAR_DATA'
}

// ets sent ekc settings
export const SettingActions = {
  UPDATE_SERVICE_DEFINITION: 'UPDATE_SERVICE_DEFINITION',
  UPDATE_SERVICE_CONFIGURATION: 'UPDATE_SERVICE_CONFIGURATION',
  UPDATE_FIRST_TRANSITION: 'UPDATE_FIRST_TRANSITION',
  UPDATE_SERVER_TIME: 'UPDATE_SERVER_TIME',
  UPDATE_FACESERVICE_USEDFOR: 'UPDATE_FACESERVICE_USEDFOR',
  SBD_SCAN_EXTEND: 'SBD_SCAN_EXTEND'
}

//ets sent ekc sessions
export const SessionActions = {
  UPDATE_SESSION: 'UPDATE_SESSION',
  UPDATE_SESSION_CONFIG: 'UPDATE_SESSION_CONFIG',
  CURRENT_SERVICE_NAME: 'CURRENT_SERVICE_NAME',
  UPDATE_TRANSITION: 'UPDATE_TRANSITION',
  UPDATE_TRANSITION_OPTION: 'UPDATE_TRANSITION_OPTION',
  INITIAL_SESSION_DATA: 'INITIAL_SESSION_DATA',
  CLEAR_SESSION_DATA: 'CLEAR_SESSION_DATA',
  CUSS_TXN_STARTED: 'CUSS_TXN_STARTED',
  UPDATE_PSAM: 'UPDATE_PSAM',
  UPDATE_NOTIFICATION_TEXT: 'UPDATE_NOTIFICATION_TEXT',
  UPDATE_ON_ACTIVE: 'UPDATE_ON_ACTIVE',
  UPDATE_ETS_TXN_STATUS: 'UPDATE_ETS_TXN_STATUS',
  UPDATE_ETS_VERSION: 'UPDATE_ETS_VERSION',
  UPDATE_ETS_TIME: 'UPDATE_ETS_TIME',
  UPDATE_ETS_TIME_DELTA: 'UPDATE_ETS_TIME_DELTA',
  UPDATE_OOS_STATUS: 'UPDATE_OOS_STATUS',
  UPDATE_ETS_REQUEST: 'UPDATE_ETS_REQUEST',
  UPDATE_IS_HEAVYTAG_REQUIRED: 'UPDATE_IS_HEAVYTAG_REQUIRED',
  UPDATE_HEAVYTAG_RETRY_COUNT: 'UPDATE_HEAVYTAG_RETRY_COUNT',
  UPDATE_SESSION_STATS: 'UPDATE_SESSION_STATS',
  UPDATE_TRANSFER_DATA: 'UPDATE_TRANSFER_DATA',
  AGENT_OVERRIDE_ERROR: 'AGENT_OVERRIDE_ERROR'
}

// ets response actions
export const ResponseActions = {
  UPDATE_PNR: 'UPDATE_PNR',
  UPDATE_FLIGHTS: 'UPDATE_FLIGHTS',
  UPDATE_PASSENGERS: 'UPDATE_PASSENGERS',
  UPDATE_ITINERARY: 'UPDATE_ITINERARY',
  UPDATE_BAGTAGDATA: 'UPDATE_BAGTAGDATA',
  UPDATE_PAX_BAGS: 'UPDATE_PAX_BAGS',
  UPDATE_REMARKS: 'UPDATE_REMARKS',
  UPDATE_BOARDING_PASS: 'UPDATE_BOARDING_PASS',
  UPDATE_BOARDING_PASS_RESOURCES: 'UPDATE_BOARDING_PASS_RESOURCES',
  UPDATE_BAGTAGS: 'UPDATE_BAGTAGS',
  UPDATE_BAGTAG_RESOURCES: 'UPDATE_BAGTAG_RESOURCES',

  RESPONSE_ERROR: 'RESPONSE_ERROR',
  INITIAL_RESPONSE_DATA: 'INITIAL_RESPONSE_DATA',
  CLEAR_RESPONSE_DATA: 'CLEAR_RESPONSE_DATA',

  UPDATE_TRAVELERS: 'UPDATE_TRAVELERS',
  // updateTravelerDocument.js
  UPDATE_TRAVELER_PHOTO_REQUIRED: 'UPDATE_TRAVELER_PHOTO_REQUIRED',
  // updateTravelData.js
  UPDATE_TRAVELER_PHOTO: 'UPDATE_TRAVELER_PHOTO',
  UPDATE_PASSENGER_DOCUMENTS: 'UPDATE_PASSENGER_DOCUMENTS',
  UPDATE_KAC_PRINT_STREAM: 'UPDATE_KAC_PRINT_STREAM',
  UPDATE_KAC_RESOURCES: 'UPDATE_KAC_RESOURCES',

  // addBags.js
  UPDATE_OVERWEIGHT_PAYMENT: 'UPDATE_OVERWEIGHT_PAYMENT'
}

export const PaxValidationActions = {
  INITIAL_PAX_DATA: 'INITIAL_PAX_DATA',
  UPDATE_DOCUMENT_IMG: 'UPDATE_DOCUMENT_IMG',
  UPDATE_FACE_IMG: 'UPDATE_FACE_IMG',
  UPDATE_PAX_PHOTO_STATUS: 'UPDATE_PAX_PHOTO_STATUS',
  UPDATE_PAX_FACE_IMG: 'UPDATE_PAX_FACE_IMG',
  UPDATE_CURRENT_PASSENGER: 'UPDATE_CURRENT_PASSENGER',
  UPDATE_DOC_RETRY_STATUS: 'UPDATE_DOC_RETRY_STATUS',
  UPDATE_DOC_TYPE: 'UPDATE_DOC_TYPE',
  UPDATE_PHOTO_MATCH_DETAIL: 'UPDATE_PHOTO_MATCH_DETAIL',
  UPDATE_IS_ACCUANT_IMAGE: 'UPDATE_IS_ACCUANT_IMAGE',
  UPDATE_SCAN_DOCUMENT_TYPE: 'UPDATE_SCAN_DOCUMENT_TYPE'
}

//local data actions
export const LocalActions = {
  SWITCH_LOCALE: 'SWITCH_LOCALE',
  SWITCH_DIRECTION: 'SWITCH_DIRECTION',
  UPDATE_BARCODE_ATTEMPTS: 'UPDATE_BARCODE_ATTEMPTS',
  UPDATE_DEVICE_ATTEMPTS: 'UPDATE_DEVICE_ATTEMPTS',
  INITIAL_LOCAL_DATA: 'INITIAL_LOCAL_DATA',
  ACTIVE: 'ACTIVE',
  ACCESSIBLE_MODE: 'ACCESSIBLE_MODE',
  CLEAR_LOCAL_DATA: 'CLEAR_LOCAL_DATA',
  DEVICE_HELP: 'DEVICE_HELP',
  QUIT_REASON: 'QUIT_REASON',
  QUIT_DETAIL: 'QUIT_DETAIL',
  APP_FLOW: 'APP_FLOW',
  BAGS_DETAILS: 'BAGS_DETAILS',
  TRANSACTION_DETAIL: 'TRANSACTION_DETAIL',
  START_TXN_NEXT_SCREEN: 'START_TXN_NEXT_SCREEN',
  BAG_MEASUREMENTS: 'BAG_MEASUREMENTS',
  BAGTAG_OBJECT: 'BAGTAG_OBJECT',
  PREV_BAG_MEASUREMENTS: 'PREV_BAG_MEASUREMENTS',
  PREV_BAGTAG_OBJECT: 'PREV_BAGTAG_OBJECT',
  BAGTAG: 'BAGTAG',
  ACTIVATED_BAGTAGS: 'ACTIVATED_BAGTAGS',
  OVERRIDE_CODE: 'OVERRIDE_CODE',
  OVERRIDE_CODE_FOR_SCAN: 'OVERRIDE_CODE_FOR_SCAN',
  TOTAL_WEIGHT: 'TOTAL_WEIGHT',
  NUMBER_OF_PROCESSED_BAGS: 'NUMBER_OF_PROCESSED_BAGS',
  TICKET: 'TICKET',
  TICKET_PECTAB: 'TICKET_PECTAB',
  PRINTDATA_TRANSITION: 'PRINTDATA_TRANSITION',
  PREV_BAG_ON_QBELT: 'PREV_BAG_ON_QBELT',
  BAG_PROCESS_STATUS: 'BAG_PROCESS_STATUS',
  AEA_BAGDROP_MSG: 'AEA_BAGDROP_MSG',
  UPDATE_DOCUMENT_ATTEMPTS: 'UPDATE_DOCUMENT_ATTEMPTS',
  UPDATE_PHOTO_ATTEMPTS: 'UPDATE_PHOTO_ATTEMPTS',
  UPDATE_TRAVEL_DOC: 'UPDATE_TRAVEL_DOC',
  UPDATE_TRAVEL_DOC_IMAGE: 'UPDATE_TRAVEL_DOC_IMAGE',
  UPDATE_PHOTO_INFO: 'UPDATE_PHOTO_INFO',
  UPDATE_ERROR_NAME: 'UPDATE_ERROR_NAME',
  UPDATE_BARCODE_DATA: 'UPDATE_BARCODE_DATA',
  MISMATCH_BP_ERROR: 'MISMATCH_BP_ERROR',
  UPDATE_BAG_OBJ: 'UPDATE_BAG_OBJ',
  UPDATE_CARRIER_CODE: 'UPDATE_CARRIER_CODE',
  UPDATE_SERVER_TIME: 'UPDATE_SERVER_TIME',
  UPDATE_CLIENT_BASE_TIME: 'UPDATE_CLIENT_BASE_TIME',
  UPDATE_START_TRANSACTION_TIME: 'UPDATE_START_TRANSACTION_TIME',
  UPDATE_ERROR_CODE_LIST: 'UPDATE_ERROR_CODE_LIST',
  TOTAL_WEIGHT_LIMIT: 'TOTAL_WEIGHT_LIMIT',
  VALIDATE_PAX_RESULT: 'VALIDATE_PAX_RESULT',
  UPDATE_SEQUENCE: 'UPDATE_SEQUENCE',
  UPDATE_IS_LAST_PASSENGER: 'UPDATE_IS_LAST_PASSENGER',
  UPDATE_POOLING: 'UPDATE_POOLING',
  UPDATE_FACE_MATCHING_STAGE: 'UPDATE_FACE_MATCHING_STAGE',
  BAGS_ENTRY_DETAILS: 'BAGS_ENTRY_DETAILS',
  UPDATE_BAGGAGE_LOADED_STATUS: 'UPDATE_BAGGAGE_LOADED_STATUS',
  UPDATE_BQ_COMMAND: 'UPDATE_BQ_COMMAND',
  UPDATE_ITINERARY_OBJ: 'UPDATE_ITINERARY_OBJ',
  UPDATE_NEXT_BAG_QUESTION: 'UPDATE_NEXT_BAG_QUESTION',
  UPDATE_LEAVE_PAGE_INDICATOR: 'UPDATE_LEAVE_PAGE_INDICATOR',
  UPDATE_TOTAL_BAG_WEIGHT: 'UPDATE_TOTAL_BAG_WEIGHT',
  UPDATE_BAG_WEIGHT: 'UPDATE_BAG_WEIGHT',
  UPDATE_BAG_WEIGHT_UNIT: 'UPDATE_BAG_WEIGHT_UNIT',
  UPDATE_BAG_ALLOWANCE: 'UPDATE_BAG_ALLOWANCE',
  UPDATE_CURRENT_PASSENGER: 'UPDATE_CURRENT_PASSENGER',
  MANUAL_SCAN_MODE: 'MANUAL_SCAN_MODE',
  SCANNED_BAGTAG: 'SCANNED_BAGTAG',
  TUB_USED: 'TUB_USED'
}

// local APIS actions
export const APISActions = {
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
  UPDATE_PAX_NAME: 'UPDATE_PAX_NAME',
  UPDATE_DOCUMENT_NO: 'UPDATE_DOCUMENT_NO',
  UPDATE_DOCUMENT_EXPIRY: 'UPDATE_DOCUMENT_EXPIRY',
  UPDATE_NATIONALITY: 'UPDATE_NATIONALITY',
  UPDATE_DATE_OF_BIRTH: 'UPDATE_DATE_OF_BIRTH',
  UPDATE_ISSUE_COUNTRY: 'UPDATE_ISSUE_COUNTRY',
  UPDATE_GENDER: 'UPDATE_GENDER',
  UPDATE_RESIDENCY: 'UPDATE_RESIDENCY',
  UPDATE_DESTINATION_STREET: 'UPDATE_DESTINATION_STREET',
  UPDATE_DESTINATION_CITY: 'UPDATE_DESTINATION_CITY',
  UPDATE_DESTINATION_STATE: 'UPDATE_DESTINATION_STATE',
  UPDATE_DESTINATION_ZIP: 'UPDATE_DESTINATION_ZIP',
  UPDATE_RENTAL_NAME: 'UPDATE_RENTAL_NAME',
  UPDATE_RENTAL_CITY: 'UPDATE_RENTAL_CITY',
  UPDATE_CRUISE_NAME: 'UPDATE_CRUISE_NAME',
  UPDATE_COMPANY_NAME: 'UPDATE_COMPANY_NAME',
  UPDATE_CONTACT_NAME: 'UPDATE_CONTACT_NAME',
  UPDATE_CONTACT_PHONE: 'UPDATE_CONTACT_PHONE',
  UPDATE_CONTACT_EMAIL: 'UPDATE_CONTACT_EMAIL',
  UPDATE_REDRESS_NUMBER: 'UPDATE_REDRESS_NUMBER',
  CLEAR_DATA: 'CLEAR_DATA',
  CLEAR_DOCUMENT: 'CLEAR_DOCUMENT',
  CLEAR_DESTINATION: 'CLEAR_DESTINATION',
  CLEAR_CONTACT: 'CLEAR_CONTACT'
}

// local FQTV actions
export const FQTVActions = {
  INITIAL_DATA: 'INITIAL_DATA',
  UPDATE_FLYER_ID: 'UPDATE_FLYER_ID',
  UPDATE_FLYER_NUMBER: 'UPDATE_FLYER_NUMBER',
  UPDATE_DATA: 'UPDATE_DATA',
  CLEAR_DATA: 'CLEAR_DATA'
}

/************************************************************/
/**                     Miscellaneous                      **/
/************************************************************/
// Media Type
export const MEDIATYPES = {
  PASSPORT: 'PASSPORT',
  IDCARD: 'IDCARD',
  FQTV: 'FQTV',
  CC: 'CC',
  VISA: 'VISA',
  OTHER: 'OTHER'
}

export const DOCUMENTTYPE = {
  PASSPORT: 'PASSPORT',
  NEXUS: 'NEXUS',
  PRCARD: 'PRCARD',
  VISA: 'VISA',
  IDCARD: 'IDCARD',
  DL: 'DL',
  UNKNOWN: 'UNKNOWN',
  OTHER: 'OTHER'
}

// passenger checkin status
export const CHECK_IN_STATUS = {
  NOT_CHECKED_IN: 'NOT_CHECKED_IN',
  CHECKED_IN_NOT_PRINTED: 'CHECKED_IN_NOT_PRINTED',
  CHECKED_IN_PRINTED: 'CHECKED_IN_PRINTED'
}

export const KIOSK_PHOTO_RESULT_CODE = {
  SUCCESS: 'SUCCESS',
  UNDER_AGE: 'UNDER_AGE',
  FAIL: 'FAIL',
  TECHNICAL_FAILURE: 'TECHNICAL_FAILURE',
  LOW_SCORE: 'LOW_SCORE',
  TIMEOUT: 'TIMEOUT'
}

export const ALGORITHM_RESULT = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  NOT_APPLICABLE: ''
}

/*******************************************************************/

export const APP_VERSION = '0.1.0'
export const LOGGED_IN = 'LOGGED_IN'
export const LOG_OUT = 'LOG_OUT'

export const SWITCH_LOCALE = 'SWITCH_LOCALE'
export const RETURN_FLIGHT = 'RETURN_FLIGHT'
export const SHOW_ERROR = 'SHOW_ERROR'
export const HIDE_ERROR = 'HIDE_ERROR'

export const QUIT = 'QUIT'
export const EXIT = 'EXIT'
export const FETCH_DATA = 'FETCH_DATA'
export const UPDATE_TRANSITION = 'UPDATE_TRANSITION'
export const UPDATE_TRANSITION_OPTION = 'UPDATE_TRANSITION_OPTION'
export const UPDATE_FASTPATH_SERVICES = 'UPDATE_FASTPATH_SERVICES'
export const UPDATE_SELECTED_PASSENGERS = 'UPDATE_SELECTED_PASSENGERS'
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT'
export const CALL_DEVICE = 'CALL_DEVICE'
export const DEVICE_RESULT = 'DEVICE_RESULT'
export const DEVICE_EVENT = 'DEVICE_EVENT'
export const MANUAL_EVENT = 'MANUAL_EVENT'
export const ERROR = 'ERROR'
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_SESSION_CONFIG = 'UPDATE_SESSION_CONFIG'
//export const UPDATE_SERVICE = 'UPDATE_SERVICE'
//export const UPDATE_CONFIG = 'UPDATE_CONFIG'
export const UPDATE_ETS_IN_SERVICE = 'UPDATE_ETS_IN_SERVICE'
//export const UPDATE_FIRST_TRANSITION = 'UPDATE_FIRST_TRANSITION'
export const CURRENT_SERVICE_NAME = 'CURRENT_SERVICE_NAME'
export const PSAM = 'PSAM' // Persistent single application mode

// navigation steps
/*
export const IDENTIFY_PAX = 'IDENTIFY_PAX'
export const PAX_DATA = 'PAX_DATA'
export const CONFIRM_CHECKIN = 'CONFIRM_CHECKIN'
export const SEAT = 'SEAT'
export const BAGGAGE = 'BAGGAGE'
export const CLOSING = 'CLOSING'
*/
export const APPLICATION_MANAGER = 'APPLICATION_MANAGER'

export const CHECKIN = 'CHECKIN'
//SBD to be vefified
export const PUT_BAG_ON_BELT = 'PUT_BAG_ON_BELT'

//added for SBD - to be confirmed
export const PROCESS_BAGS = 'PROCESS_BAGS'
export const CLOSING_REMARKS = 'CLOSING_REMARKS'

export const TRANSACTION_STARTED = 'TRANSACTION_STARTED'
/************************************************************/
/**          SBD application                               **/
/************************************************************/
export const APP_FLOW = 'APP_FLOW'
export const BAG_MEASUREMENTS = 'BAG_MEASUREMENTS'
export const BAGTAG_OBJECT = 'BAGTAG_OBJECT'
export const BAGTAG = 'BAGTAG'
export const ACTIVATED_BAGTAGS = 'ACTIVATED_BAGTAGS'
export const TOTAL_WEIGHT = 'TOTAL_WEIGH'
export const NUMBER_OF_PROCESSED_BAGS = 'NUMBER_OF_PROCESSED_BAGS'
export const BAG_TXN_NOMORE = 'BAG_TXN_NOMORE'
export const PREV_BAG_MEASUREMENTS = 'PREV_BAG_MEASUREMENTS'
export const PREV_BAGTAG_OBJECT = 'PREV_BAGTAG_OBJECT'
export const PREV_BAG_ON_QBELT = 'PREV_BAG_ON_QBELT'
export const TICKET = 'TICKET'
export const TICKET_PECTAB = 'TICKET_PECTAB'
export const PRINTDATA_TRANSITION = 'PRINTDATA_TRANSITION'
export const BAG_PROCESS_STATUS = 'BAG_PROCESS_STATUS'

export const FALSE = 0
export const TRUE = 1
export const SESSION_START = 'SESSION_START'
export const OOS = 'OOS'
export const RETRY = 'RETRY'

export const TYPE_KIOSK = 'SBD'
export const COMPLETE_OK = 'COMPLETE_OK'
export const PAX_ADULT = 'ADULT'
export const PAX_CHILD = 'CHILD'
export const CARRIER_CODE = 'CC'

export const TIMEOUT_POPUP = 0
export const TIMEOUT_YES_BTN = 1
export const TIMEOUT_NO_BTN = 2
export const QUIT_POPUP = 0
export const QUIT_YES_BTN = 1
export const QUIT_NO_BTN = 2
export const HELP_POPUP = 0
export const HELP_OK_BTN = 1

export const PASSENGER_VALIDATION_STATUS = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  FAILED: 'FAILED',
  PASSED: 'PASSED',
  UNKNOWN: 'UNKNOWN'
}
export const PRINT_DOC_TYPE = {
  BOARDING_PASS: 'BOARDING_PASS',
  BAG_TAG: 'BAG_TAG',
  HEAVY_BAG_TAG: 'HEAVY_BAG_TAG',
  SBD_RECEIPT: 'SBD_RECEIPT'
}
export const TOTAL_WEIGHT_UNIT = {
  WEIGHT_KG: 'KG',
  WEIGHT_LB: 'LB'
}

export const AcuantStatus = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  ATTENTION: 'Attention',
  UNKNOWN: 'Unknown',
  OPPOSITE: 'OPPOSITE'
}
export const HEADSET = 'HEADSET'
export const KEYPAD = 'KEYPAD'

export const CONVEYOR_POSITION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

/************************************************************/
/**                      Keypad Type                       **/
/************************************************************/
export const ALPHA_KEYPAD = 'ALPHA_KEYPAD'
export const NUMERIC_KEYPAD = 'NUMERIC_KEYPAD'
export const SYMBOL_KEYPAD = 'SYMBOL_KEYPAD'

export const BEEP_SOUND_TYPE = {
  BEEP_OK: 'BEEP_OK',
  BEEP_ERROR: 'BEEP_ERROR'
}

export const CURRENCY_FORMAT = {
  LONG: '',
  SHORT: 'SHORT',
  INT: 'INT',
  CODE: 'CODE'
}

export const ACC_POPUPS = {
  POPUP: 'POPUP',
  POPUP_HIDE: 'POPUP_HIDE',
  POPUP_SHOW: 'POPUP_SHOW'
}
