import {
  store,
  getAccessibilityDevice,
  getDeviceManager,
  getSBDAppMan,
  getTSDManager,
  getVersion,
  history,
  getEventLogger,
  getOOSManager
} from 'main'
//import { updateAppData, updateDeviceHelp } from 'actions/deviceActions'
import { updateAppData } from 'actions/appActions'
import { updateSessions } from 'actions/sessionActions'
import { startEtsTransaction, initialStore } from 'actions/etsTransactions/sessionAct'
import { startCUSSTransaction } from 'utils/appTransactions'
//import { updateError } from 'actions/commonActions'
import { transitions } from 'actions/etsTransactions/transitions'
import { TYPE_KIOSK, TransitionCodes, ETS_TXN_STATUS, OOS, HEADSET, KEYPAD } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { turnLights } from 'utils/lights'
import { getKeyByValue, goToLocalGenericError, xmlToJson, navigate, goToLocalError } from 'utils/helper'
import { TraceLevels, deviceIds, EnvInfoEventType } from 'embross-device-manager'
import { appLog } from 'utils/Logger'
import { kioskIdAndLocationReady } from 'custom/callbacks'
import { updateManualScanMode } from '../actions/deviceActions'

export function appMgrOnChange(change) {
  appLog(TraceLevels.LOG_TRACE, 'appMgrOnChange() is called from client: ' + change.key + ' ' + change.value)
  const tsdMgr = getTSDManager()
  //const eventLogger = getEventLogger()
  if (change.key === 'kioskId') {
    //eventLogger.KioskId = change.value
    tsdMgr.kioskId = change.value
    config.currentKioskId = change.value
  } else if (change.key === 'location') {
    //eventLogger.Location = change.value
    tsdMgr.locationId = change.value
    config.currentLocation = change.value
  }
  if (config.currentKioskId && config.currentLocation && change.key !== 'kioskId' && change.key !== 'location') {
    kioskIdAndLocationReady(config.currentKioskId, config.currentLocation)
  }
  store.dispatch(updateAppData(change.key, change.value))

  /* if (change.key === 'appName' && getTSDManager()) {
    getTSDManager().addEnvInfoEvent(EnvInfoEventType.APP_INFO, change.value, getVersion())
  } */
}

export function appMgrOnActive() {
  let location = {
    state: {
      from: 'CUSSActive'
    }
  }
  store.dispatch(updateAppData('onActive', true))
  let url = Object.assign({}, location, { pathname: config.firstScreen })
  appLog(TraceLevels.LOG_EXT_TRACE, 'appMgrOnActive() url: ' + JSON.stringify(url))
  navigate(url)

  //send session start
  // appLog(TraceLevels.LOG_EXT_TRACE,'main: application is active')
  //  if (change.value === true)
  //   startEtsTransaction(store,TYPE_KIOSK,getVersion())
}

// start ETS transaction for non PSAM apps when notification text arrives
export function appMgrOnEvent(event) {
  let accessibility = getAccessibilityDevice()
  appLog(TraceLevels.LOG_EXT_TRACE, 'appMgrOnEvent() is called from client: ' + event.key + ' ' + event.value)

  if (event.key === 'notificationText') {
    console.debug('notificationText:', event.value)
    // if ETS transaction status is not ETS_TXN_NONE then reset it
    if (store.getState().sessions.ETSTxnStatus !== ETS_TXN_STATUS.ETS_TXN_NONE) {
      store.dispatch(updateSessions('updateEtsTxnStatus', ETS_TXN_STATUS.ETS_TXN_NONE))
    }
    // initialize store (clear data and initializing)
    appLog(TraceLevels.LOG_TRACE, 'initialize store...')
    initialStore(store.dispatch)
    getTSDManager().clearTxn(true, -1)
    store.dispatch(updateSessions('updateNotificationText', event.value))
    // check if PSAM mode - if yes then just store it and wait for interaction to start transactions
    if (event.value.indexOf('SINGLEAPP MODE') >= 0) {
      store.dispatch(updateAppData('PSAM', true))
    } else {
      store.dispatch(updateAppData('PSAM', false))
    }

    if (accessibility) {
      if (event.value.indexOf('ACCESSIBLE=TRUE') >= 0) {
        appLog(TraceLevels.LOG_TRACE, 'Start in accessible mode')
        // add accessibility styles
        if (document.styleSheets[0].cssRules[0].selectorText === ':focus') {
          document.styleSheets[0].deleteRule(0)
          document.styleSheets[0].insertRule(':focus { outline: yellow solid 4px; }', 0)
        }
        accessibility.enabled = true
        accessibility.DeviceEvent = { key: 'enabled', value: true }
        accessibility.enableHeadset()
        accessibility.enableKeypad()
        accessibility.enableTTS()
      } else {
        appLog(TraceLevels.LOG_EXT_TRACE, 'appMgrOnEvent() disable accessibility mode.')
        // remove accessibility styles
        if (document.styleSheets[0].cssRules[0].selectorText === ':focus') {
          document.styleSheets[0].deleteRule(0)
          document.styleSheets[0].insertRule(':focus { outline: none; }', 0)
        }
        accessibility.enabled = false
        accessibility.DeviceEvent = { key: 'enabled', value: false }
      }
    }

    if (event.value.indexOf('TRANSFER_INFO=') >= 0) {
      let transferData = null
      try {
        const position = event.value.indexOf('TRANSFER_INFO=')
        const jsonString = event.value.substring(position + 'TRANSFER_INFO='.length)
        transferData = JSON.parse(jsonString)
        console.log('transferInfo:', transferData)
      } catch (error) {
        console.log('Cannot parse string:', error, event.value, transferData)
      }
      store.dispatch(updateSessions('updateTransferData', transferData))
    }

    history.push(config.firstScreen)
    turnLights('kioskAvailable', true)
  }

  if (event.key === 'accessibleMode') {
    if (event.value === true) {
      appLog(TraceLevels.LOG_TRACE, 'appMgrOnEvent() start accessibility mode.')
      // add accessibility styles
      if (document.styleSheets[0].cssRules[0].selectorText === ':focus') {
        document.styleSheets[0].deleteRule(0)
        document.styleSheets[0].insertRule(':focus { outline: yellow solid 4px; }', 0)
      }
      accessibility.enabled = true
      accessibility.DeviceEvent = { key: 'enabled', value: true }
      appLog(TraceLevels.LOG_EXT_TRACE, 'enable keypad and tts, accessibility: ' + accessibility)
      accessibility.enableKeypad()
      accessibility.enableTTS()
    } else {
      appLog(TraceLevels.LOG_TRACE, 'accessibleMode = false remove outline style')
      if (document.styleSheets[0].cssRules[0].selectorText === ':focus') {
        document.styleSheets[0].deleteRule(0)
        document.styleSheets[0].insertRule(':focus { outline: none; }', 0)
      }
      accessibility.enabled = false
      accessibility.DeviceEvent = { key: 'enabled', value: false }
      appLog(TraceLevels.LOG_EXT_TRACE, 'disable keypad and tts, accessibility: ' + accessibility)
      accessibility.disableKeypad()
      accessibility.disableTTS()
      accessibility.enableHeadset()
    }
    // dispatch event for EZAKPManager
    accessibility.DeviceEvent = { key: 'accessibleMode', value: accessibility.enabled }
  }
  if (event.key === 'restart') {
    //
  }
  if (event.key === 'almostOutOfTime') {
    let appFlow = store.getState().localData.appFlow
    if (appFlow <= 0) {
      getSBDAppMan().doQuit(ErrCodes.TRANSACTION_TIMEOUT, '', '')
    } else {
      appLog(TraceLevels.LOG_EXT_TRACE, 'appMgrOnEvent event key: ' + event.key + ' ,appFlow: ' + appFlow)
      goToLocalGenericError('appMgrOnEvent', ErrCodes.TRANSACTION_TIMEOUT, 'Error_Timeout', 'END_TXN', null)
    }
  }

  if (event.key === 'getConfig') {
    appLog(TraceLevels.LOG_EXT_TRACE, 'getConfig from event: ' + event.value)
    const parts = event.value ? event.value.split(',') : null
    console.log('parts:', JSON.stringify(parts))
    if (parts && parts.length === 2) {
      console.log('updated:', parts[0], parts[1])
      store.dispatch(updateAppData(parts[0], parts[1]))
    }
  }

  if (event.key === 'applicationTransfer') {
    appLog(TraceLevels.LOG_EXT_TRACE, 'applicationTransfer response: ' + event.value)
    if (event.value !== '0') {
      goToLocalGenericError(
        'commandCompleted appFlow 20',
        ErrCodes.TARGET_APP_NOT_AVAILABLE,
        'Error_Default',
        'END_TXN',
        null
      )
    } else {
      getSBDAppMan().doQuit('App_Transfer', '')
    }
  }
}

export function onOOSEvent(devId, code) {
  appLog(TraceLevels.LOG_SYSTEM, 'onOOSEvent: ' + devId + ' code: ' + code)
  turnLights('kioskAvailable', false)
  let appm = getDeviceManager().getAppManager()
  if (appm && !appm.getIsActive()) {
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: app is not in active state - skip it.')
    return
  }
  store.dispatch(updateAppData('oosEvent', devId + ' code: ' + code))
  let devName = getKeyByValue(deviceIds, devId)
  appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: device Name: ' + devName)
  getDeviceManager().areAllRequiredDevicesOK(true)
  if (store.getState().sessions.OOS) {
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: already in OOS mode')
    return
  }
  // check if device id is a required device
  if (!config.requiredDevices.includes(devName)) {
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: skip - not required device')
    return
  }

  // start OOS processing -
  let appFlow = store.getState().localData.appFlow
  appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: appFlow: ' + appFlow)
  if (appFlow === 10) {
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: already in quit mode appFlow is 10')
  }
  let errorCode = ErrCodes.DEVICE_ERROR // should be more detailed based on devId and code
  if (
    appFlow === 0 &&
    (store.getState().sessions.ETSTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE ||
      store.getState().sessions.ETSTxnStatus === ETS_TXN_STATUS.ETS_TXN_FAILED)
  ) {
    store.dispatch(updateSessions('updateOOSstatus', true))
    getSBDAppMan().doQuit(errorCode, OOS)
  } else if (appFlow === 0 && store.getState().sessions.ETSTxnStatus === ETS_TXN_STATUS.ETS_TXN_STARTED) {
    store.dispatch(updateSessions('updateOOSstatus', true))
    //  waiting for host response - then close txn in response handler
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: updateOOSstatus called.')
  } else if (devId === deviceIds.BARCODE_READER && appFlow >= 2) {
    // can continue - at the end (completeCUSSTransaction) will check if required device is
    // still not OK and got to unavailable state
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: continue barcode not required to finish the transaction.')
  } else if (store.getState().sessions.ETSRequest) {
    // in ETS request
    store.dispatch(updateSessions('updateOOSstatus', true))
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: updateOOSstatus called.')
    // on ETS response OK and error store.dispatch(transitions(TransitionCodes.OOS_ERROR, null))
  } else if (devId !== deviceIds.AEA_BAGDROP) {
    // display error page and then go to OOS
    // AEA BAGDROP errors should be handled in sbdAppManager
    store.dispatch(updateSessions('updateOOSstatus', true))
    appLog(TraceLevels.LOG_TRACE, 'onOOSEvent: updateOOSstatus called. goto error page')
    store.dispatch(transitions(TransitionCodes.OOS_ERROR, null))
  }
}

function saveDeviceHelp(devId, data, comp = null) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'saveDeviceHelp() called ... device ID: ' + getKeyByValue(deviceIds, devId))
  appLog(TraceLevels.LOG_EXT_TRACE, 'saveDeviceHelp() called ... data: ' + JSON.stringify(data))
  if (data != null && data !== '') {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(data, 'text/xml')
    let deviceHelp = xmlToJson(xmlDoc)
    //store.dispatch(updateDeviceHelp(devId, deviceHelp, comp))
  } else {
    appLog(TraceLevels.LOG_TRACE, 'saveDeviceHelp() missing device help for: ' + devId)
  }
}

export function ATBPrinterOnEvent(event) {
  const atbPrinter = getDeviceManager().getDevice(deviceIds.ATB_PRINTER)
  appLog(TraceLevels.LOG_EXT_TRACE, 'ATBPrinterOnEvent() is called from client:' + event.key + ' ' + event.value)
  if (
    event.key === 'statusChange' &&
    ((event.value[0] > 300 && event.value[0] < 310) || event.value[1]) &&
    atbPrinter.isDeviceRequired()
  ) {
    const appManager = getDeviceManager().appManager
    const oosManager = getOOSManager()
    if (appManager.getIsActive()) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: bagtag Printer is disconnected')
      goToLocalError('Callbacks', ErrCodes.ATB_PRINTER_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
    } else {
      appManager.unavailable()
      oosManager.inService = false
    }
  } else {
    console.log('callbacks: ATB Printer status:', event.value[0])
  }
}

export function BagtagPrinterOnEvent(event) {
  const bagtagPrinter = getDeviceManager().getDevice(deviceIds.BAGTAG_PRINTER)
  appLog(TraceLevels.LOG_EXT_TRACE, 'BagtagPrinterOnEvent() is called from client:' + event.key + ' ' + event.value)
  if (
    event.key === 'statusChange' &&
    ((event.value[0] > 300 && event.value[0] < 310) || event.value[1]) &&
    bagtagPrinter.isDeviceRequired()
  ) {
    const appManager = getDeviceManager().appManager
    const oosManager = getOOSManager()
    if (appManager.getIsActive()) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: bagtag Printer is disconnected')
      goToLocalError('Callbacks', ErrCodes.BAGTAG_PRINT_ERROR, 'PrintError', 'END_TXN', null, 'ErrorPrint')
    } else {
      appManager.unavailable()
      oosManager.inService = false
    }
  } else {
    console.log('callbacks: bagtag Printer status:', event.value[0])
  }
}

export function passportReaderOnEvent(event) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'passportReaderOnEvent() is called from client:' + event.key + ' ' + event.value)
  const passportReader = getDeviceManager().getDevice(deviceIds.PASSPORT_READER)
  if (event.key === 'getKioskDeviceHelp') {
    saveDeviceHelp(deviceIds.PASSPORT_READER, event.value)
  } else if (
    event.key === 'statusChange' &&
    ((event.value[0] > 300 && event.value[0] < 310) || event.value[1]) &&
    passportReader.isDeviceRequired()
  ) {
    const appManager = getDeviceManager().appManager
    const oosManager = getOOSManager()
    if (appManager.getIsActive()) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: passport  reader is disconnected')
      goToLocalGenericError('Callbacks', ErrCodes.PASSPORT_ERROR, 'PassportReaderError', 'END_TXN')
    } else {
      appManager.unavailable()
      oosManager.inService = false
    }
  } else {
    console.log('callbacks: passport reader status:', event.value[0])
  }
}

export function barcodeReaderOnEvent(event) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'barcodeReaderOnEvent() is called from client:' + event.key + ' ' + event.value)
  const barcodeReader = getDeviceManager().getDevice(deviceIds.BARCODE_READER)
  if (event.key === 'getKioskDeviceHelp') {
    saveDeviceHelp(deviceIds.BARCODE_READER, event.value)
  } else if (
    event.key === 'statusChange' &&
    ((event.value[0] > 300 && event.value[0] < 310) || event.value[1]) &&
    barcodeReader.isDeviceRequired()
  ) {
    const appManager = getDeviceManager().appManager
    const oosManager = getOOSManager()
    if (appManager.getIsActive()) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: barcode  reader is disconnected')
      goToLocalGenericError('Callbacks', ErrCodes.BARCODE_ERROR, 'BarcodeReaderError', 'END_TXN')
    } else {
      appManager.unavailable()
      oosManager.inService = false
    }
  } else if (event.key === 'getVersion') {
    appLog(TraceLevels.LOG_EXT_TRACE, 'callback: barcode  reader version:' + event.value)
    if (event.value.includes(config.handHeldScannerKey)) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: barcode  reader version, set Manual Scan: ', true)
      store.dispatch(updateManualScanMode(true))
    } else {
      appLog(
        TraceLevels.LOG_EXT_TRACE,
        'callback: barcode  reader version: key=> ',
        config.handHeldScannerKey,
        event.value.includes(config.handHeldScannerKey)
      )
      store.dispatch(updateManualScanMode(false))
    }
  } else {
    console.log('callbacks: barcode reader status:', event.value[0])
  }
}

export function cardReaderOnEvent(event) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'cardReaderOnEvent() is called from client:' + event.key + ' ' + event.value)
  if (event.key === 'getKioskDeviceHelp') saveDeviceHelp(deviceIds.CARD_READER, event.value)
}
export function AEABagdropOnEvent(event) {
  const bagdropDevice = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  appLog(TraceLevels.LOG_EXT_TRACE, 'AEABagdropOnEvent() is called from client:' + event.key + ' ' + event.value)
  if (event.key === 'getKioskDeviceHelp') {
    saveDeviceHelp(deviceIds.AEA_BAGDROP, event.value)
  } else if (
    event.key === 'statusChange' &&
    ((event.value[0] > 300 && event.value[0] < 310) || event.value[1]) &&
    bagdropDevice.isDeviceRequired()
  ) {
    const appManager = getDeviceManager().appManager
    const oosManager = getOOSManager()
    if (appManager.getIsActive()) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: AEA bag drop is disconnected')
      goToLocalGenericError('Callbacks', ErrCodes.DEVICE_ERROR, 'BagDropDeviceError', 'END_TXN')
    } else {
      appManager.unavailable()
      oosManager.inService = false
    }
  } else {
    console.log('callbacks: bag drop device status:', event.value[0])
  }
}

export function faceTrackingOnEvent(event) {
  const factTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
  appLog(TraceLevels.LOG_EXT_TRACE, 'faceTrackingOnEvent() called ... event : ' + JSON.stringify(event))
  if (event.key === 'getKioskDeviceHelp') {
    saveDeviceHelp(deviceIds.FACE_TRACKING, event.value, true)
  } else if (
    event.key === 'statusChange' &&
    ((event.value[0] > 300 && event.value[0] < 310) || event.value[1]) &&
    factTracking.isDeviceRequired()
  ) {
    const appManager = getDeviceManager().appManager
    const oosManager = getOOSManager()
    if (appManager.getIsActive()) {
      appLog(TraceLevels.LOG_EXT_TRACE, 'callback: face tracking is disconnected')
      goToLocalGenericError('Callbacks', ErrCodes.CAMERA_ERROR, 'FaceTrackingError', 'END_TXN')
    } else {
      appManager.unavailable()
      oosManager.inService = false
    }
  } else {
    console.log('callbacks: face tracking status:', event.value[0])
  }
}
export function accessibilityOnEvent(event) {
  appLog(TraceLevels.LOG_EXT_TRACE, 'accessibilityOnEvent() called ... event : ' + JSON.stringify(event))
  if (event.key === 'getHeadsetDeviceHelp') {
    saveDeviceHelp(deviceIds.CUSS_ACCESSIBILITY, event.value, HEADSET)
  } else if (event.key === 'getKeypadDeviceHelp') {
    saveDeviceHelp(deviceIds.CUSS_ACCESSIBILITY, event.value, KEYPAD)
  }
}
