import { ResponseActions } from 'constants/Constants'

export function updateResponses(state = '', action) {
  let data = Object.assign({}, state)
  switch (action.type) {
    // getItinerarySBD.js
    case ResponseActions.UPDATE_ITINERARY:
      data.itineraryInfo = action.itineraryInfo
      break

    case ResponseActions.UPDATE_BAGTAGDATA:
      data.itineraryInfo.passengerBagtagDetails[action.passengerBagtagDetails.paxIx].bagTags =
        action.passengerBagtagDetails.bagtagData
      break

    case ResponseActions.UPDATE_REMARKS:
      data.remarks = action.remarks
      break
    case ResponseActions.RESPONSE_ERROR:
      data.errorDetails = action.errorDetails
      break
    case ResponseActions.INITIAL_RESPONSE_DATA:
      return ''
    case ResponseActions.CLEAR_RESPONSE_DATA:
      return ''
    case ResponseActions.UPDATE_TRAVELERS:
      data.travelers = action.travelers
      break
    case ResponseActions.UPDATE_TRAVELER_PHOTO_REQUIRED:
      data.travelerPhotoRequired = action.travelerPhotoRequired
      break
    case ResponseActions.UPDATE_TRAVELER_PHOTO:
      data.travelerPhoto = action.travelerPhoto
      break
    case ResponseActions.UPDATE_BAGTAG_RESOURCES:
      data.bagtagResources = action.bagtagResources
      break
    case ResponseActions.UPDATE_PASSENGER_DOCUMENTS:
      data.paxDocuments = action.paxDocuments
      break
    case ResponseActions.UPDATE_KAC_PRINT_STREAM:
      data.kacPrintstream = action.kacPrintstream
      break
    case ResponseActions.UPDATE_KAC_RESOURCES:
      data.kacResources = action.kacResources
      break
    case ResponseActions.UPDATE_OVERWEIGHT_PAYMENT:
      data.overweightPayment = action.overweightPayment
      break
    default:
      return state
  }
  return data
}
