import { checkTravelDoc } from 'custom/actions/checkTravelDoc'
import { genericCommand } from 'actions/etsTransactions/genericCommand'
import { updateLocalData } from 'actions/localActions'
import { updatePaxValidationData } from 'actions/paxValidationActions'
import { Animation } from 'components'
import { DynamicImage, PageContent, PageSubContent, PageSubTitle, PageTitle, Spacer } from 'components/styledComponents'
import { checkTravelDocAction, MEDIATYPES, PASSENGER_VALIDATION_STATUS } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import usePassportScanner from 'hooks/usePassportScanner'
import {
  getAcuantClient,
  getBuildAccessibility,
  getDeviceManager,
  getSummaryStore,
  history,
  playSound,
  store
} from 'main'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { EventFlows, EventFunctions, logEvents } from 'utils/appEventLogger'
import { documentCheck } from './documentCheck'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId, goToLocalGenericError, maskPCI } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { GenerateChipPP, GeneratePP, samplePPData } from 'utils/passportGenerator'
import { Footer } from 'layouts/footer'

const ScanDocuments = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const buildAccessibility = getBuildAccessibility()
  const validatePaxResult = useSelector((state) => state.localData.validatePaxResult)
  const currentPassenger = useSelector((state) => state.paxValidation.currentPassenger)
  const docRetryStatus = useSelector((state) => state.paxValidation.docRetryStatus)
  const locale = useSelector((state) => state.localData.locale)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const location = useLocation()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const isLandscape = useCheckLandscape()
  const themeContext = useContext(ThemeContext)
  const acuantClient = getAcuantClient()
  const itineraryInfo = store.getState().responses.itineraryInfo
  const summaryStore = getSummaryStore()

  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const updatePassportScanned = (result) => {
    let newValidateResults = []
    let updatedItem = {}
    validatePaxResult.forEach((item) => {
      if (item.ordinal === currentPassenger.ordinal) {
        updatedItem = item
        updatedItem.passportScanned = result
        newValidateResults.push(updatedItem)
      } else {
        newValidateResults.push(item)
      }
    })
    dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
  }
  const scannedCallback = (mediaData, image, docType) => {
    logEvents(EventFlows.DocScan, EventFunctions.DocScan, 'success')
    logEvents(EventFlows.DocScan, EventFunctions.DocType, docType)
    logEvents(EventFlows.DocScan, EventFunctions.DocMRZ, maskPCI(JSON.stringify(mediaData), deviceIds.PASSPORT_READER))
    let retryCount = store.getState().localData.errorDeviceAttempts
    // props.updateLocalData('updateTravelDocImage', image)
    if (image.faceImage) {
      dispatch(updatePaxValidationData('updateFaceImage', image.faceImage))
      dispatch(updatePaxValidationData('updateIsAccuantImage', false))
    }
    dispatch(updatePaxValidationData('updateDocumentImg', image.docImage))
    dispatch(updatePaxValidationData('updateScanDocumentType', docType))
    if (config.validateDocument) {
      const socketStatus = acuantClient.getStatus()

      if (socketStatus) {
        logEvents(EventFlows.Acuant, EventFunctions.AcuantSecondSide, config.acuantSingleScan ? 'No' : 'Yes')
        documentCheck(image.docImage, null, mediaData)

        // if (docType === MEDIATYPES.PASSPORT || config.acuantSingleScan) {
        //   appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js)  acuant doc check')
        //   documentCheck(image.docImage, null, mediaData)
        // } else {
        //   history.push('ScanOppositeDoc')
        // }
      } else {
        const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
        faceTracking.disable()
        faceTracking.OnDeviceEvent = null
        let newValidateResults = []
        let updatedItem = {}
        validatePaxResult.forEach((item) => {
          if (item.ordinal === currentPassenger.ordinal) {
            updatedItem = item
            updatedItem.passportScanned = false
            updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED

            newValidateResults.push(updatedItem)
          } else {
            newValidateResults.push(item)
          }
        })

        summaryStore.updateDocScan()
        summaryStore.updateDocScanError('ACCUANT_CONNECTION_FAIL')
        summaryStore.endDocScan(true)

        const updatePaxStatus = {
          ordinal: currentPassenger.ordinal,
          docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
        }

        dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
        dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
        dispatch(updatePaxValidationData('initialPaxData'))
        dispatch(updateLocalData('updateDeviceAttempts', 0))
      }
    } else {
      updatePassportScanned(true)
      if (docType === MEDIATYPES.PASSPORT) {
        dispatch(
          checkTravelDoc(mediaData, null, checkTravelDocAction.CHECK_DOCINFO_ONLY, config.validateDocument, false)
        )
      } else {
        if (retryCount + 1 === config.scanDocRetryCount) {
          // max retry hit, go to agent
          const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
          faceTracking.disable()
          faceTracking.OnDeviceEvent = null
          let newValidateResults = []
          let updatedItem = {}
          validatePaxResult.forEach((item) => {
            if (item.ordinal === currentPassenger.ordinal) {
              updatedItem = item
              updatedItem.passportScanned = false
              updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
              updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
              updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
              updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED

              newValidateResults.push(updatedItem)
            } else {
              newValidateResults.push(item)
            }
          })

          const updatePaxStatus = {
            ordinal: currentPassenger.ordinal,
            docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
            privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
          }

          summaryStore.endDocScan(true)
          dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
          dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
          dispatch(updatePaxValidationData('initialPaxData'))
          dispatch(updateLocalData('updateDeviceAttempts', 0))
          acuantClient && acuantClient.close()
        } else {
          dispatch(updateLocalData('updateDeviceAttempts', retryCount + 1))
          summaryStore.updateDocScanError('DOC_TYPE_NOT_SUPPORT')
          goToLocalGenericError(
            'checkTravelDoc',
            ErrCodes.ERR_PASSPORT_INVALID_RETRY,
            'Error_PassportRetry',
            'ScanDocuments',
            null,
            {
              log: false
            }
          )
        }
      }
    }
  }

  const [enable, disable, passportReaderCallback, popupContainer] = usePassportScanner(scannedCallback, sbdModel)

  const getScanSequence = (validatePaxResult) => {
    let remains = null
    remains = validatePaxResult.filter((pax) => {
      return pax.passportScanned === true
    })
    return remains.length + 1
  }
  useEffect(() => {
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js)  did mount,call scan passport enable function...')
    dispatch(updatePaxValidationData('updateFaceImage', null))
    if (config.isCUSSRequired) {
      enable()
      acuantClient && acuantClient.open({})
    }

    return () => {
      appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) UNMOUNTED')
      //turnLights('scanPassport', false)
      if (config.isCUSSRequired) {
        appLog(TraceLevels.LOG_TRACE, '(ScanDocuments.js) disable passport reader.')
        disable()
      }
    }
  }, [])

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  useEffect(() => {
    handleAccessibility()
  }, [docRetryStatus, locale])

  let travelerName = currentPassenger
    ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
    : getScanSequence(validatePaxResult)

  const handleAccessibility = () => {
    const screenId = getScreenId(location.pathname)
    const textParameters = docRetryStatus
      ? [intl.formatMessage(messages.ScanDocs_Retry), intl.formatMessage(messages.ScanDocs_SubTitle)]
      : [
          intl.formatMessage(messages.ScanDocs_Title).replace('paxName', travelerName),
          intl.formatMessage(messages.ScanDocs_SubTitle)
        ]
    const accDef = {
      pathName: 'ScanDocuments',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        screenId
      }),
      itineraryTextParameters: [`${bagWeight} ${bagWeightUnit ? bagWeightUnit.toLowerCase() : ''}`],
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'TwoDynamicText',
            textParameters
          }
        ]
      }
    }

    // if (docRetryStatus) {
    //   accDef.sequenceDef.sequence.push({
    //     id: 'confirmBtn',
    //     text: intl.formatMessage(messages.ScanDocs_NoOtherDocument),
    //     buttonId: 'confirmBtn'
    //   })
    // }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const getNextPassenger = (validatePaxResult) => {
    let remains = null
    remains = validatePaxResult.filter((pax) => {
      return pax.passportScanned === false
    })
    if (remains.length > 0) {
      return remains[0]
    } else {
      return validatePaxResult[0]
    }
  }

  const handleClick = (e) => {
    let passportData = null
    let testPassenger = getNextPassenger(validatePaxResult)
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) : handleClick() ... ' + e.currentTarget.id)
    switch (e.currentTarget.id) {
      case 'buttonScanDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        const passenger = {
          firstName: testPassenger.firstName,
          lastName: testPassenger.lastName,
          dateOfBirth: testPassenger.dateOfBirth || '871012',
          nationalityCode: 'CAN'
        }
        passportData = GenerateChipPP(passenger, config.testPhoto)
        //passportData = samplePPData
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportInserted',
          value: passportData
        })
        setTimeout(() => {
          passportReaderCallback({
            key: 'passportReadInternal',
            value: passportData
          })
        }, 2000)
        setTimeout(() => {
          passportReaderCallback({
            key: 'passportRemoved',
            value: null
          })
        }, 6000)

        break
      case 'buttonSwipeDocs':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        passportData = GeneratePP(
          testPassenger.firstName,
          testPassenger.lastName,
          testPassenger.dateOfBirth || '871012',
          'CAN',
          config.testPhoto
        )
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        break
      case 'buttonScanDocsRemove':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = samplePPData
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        setTimeout(() => disable(), 7000)
        break
      case 'buttonNotReadable':
        appLog(TraceLevels.LOG_EXT_TRACE, 'Event:  passportDamaged ')
        /*  passportReaderCallback({
          key: 'passportDamaged',
          value: ''
        }) */
        goToLocalGenericError(
          'checkTravelDoc',
          ErrCodes.ERR_PASSPORT_INVALID_RETRY,
          'Error_PassportRetry',
          'ScanDocuments',
          null,
          {
            log: false
          }
        )
        break
      case 'buttonInvalidPassport':
        passportReaderCallback({
          key: 'passportInserted',
          value: null
        })
        passportData = GeneratePP('FirstName', 'LastName', null)
        appLog(TraceLevels.LOG_EXT_TRACE, '(ScanDocuments.js) handleClick() - Data Read: ' + passportData)
        passportReaderCallback({
          key: 'passportReadInternal',
          value: passportData
        })
        passportReaderCallback({
          key: 'passportRemoved',
          value: null
        })
        break
      case 'buttonScanDL':
        const formatResponse = {
          documentNo: 'C3435-38309-50713',
          expiryDate: '7/13/2027',
          gender: 'MALE',
          lastName: 'CHIDDETI',
          dateOfBirth: '7/13/1995',
          documentType: 'DL',
          firstName: 'JAGADEEP',
          issuingCountry: 'CAN'
        }
        dispatch(updatePaxValidationData('updateFaceImage', '/justsomerandombase64imagetest'))
        dispatch(checkTravelDoc(null, formatResponse, 'CHECK_DOCINFO_ONLY', config.validateDocument))
        break
      case 'buttonDisconnect':
        passportReaderCallback({
          key: 'statusChange',
          value: [302, true]
        })
        break
      default:
        appLog(TraceLevels.LOG_EXT_TRACE, 'push: /')
        history.push(config.firstScreen)
    }
  }

  const handleActions = (e) => {
    switch (e.currentTarget.id) {
      case 'confirmBtn':
        let newValidateResults = []
        let updatedItem = {}
        validatePaxResult.forEach((item) => {
          if (item.ordinal === currentPassenger.ordinal) {
            updatedItem = item
            updatedItem.bioCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.docCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.visaCheck = PASSENGER_VALIDATION_STATUS.FAILED
            updatedItem.privacyAgreement = PASSENGER_VALIDATION_STATUS.PASSED

            newValidateResults.push(updatedItem)
          } else {
            newValidateResults.push(item)
          }
        })

        const updatePaxStatus = {
          ordinal: currentPassenger.ordinal,
          docCheckOk: updatedItem.docCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          faceMatch: updatedItem.bioCheck === PASSENGER_VALIDATION_STATUS.PASSED,
          privacyAgreement: updatedItem.privacyAgreement === PASSENGER_VALIDATION_STATUS.PASSED
        }

        summaryStore.endDocScan(true)
        dispatch(updateLocalData('updateValidatePaxResult', newValidateResults))
        dispatch(genericCommand('updatePaxStatus', { paxStatus: updatePaxStatus }, null))
        dispatch(updatePaxValidationData('initialPaxData'))
        dispatch(updateLocalData('updateDeviceAttempts', 0))
        break
      default:
        break
    }
    history.push(url)
  }

  const testButtons = [
    {
      id: 'buttonScanDocs',
      text: 'Scan',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonSwipeDocs',
      text: 'Swipe',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanDocsRemove',
      text: 'Remove',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonInvalidPassport',
      text: 'Invalid',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonNotReadable',
      text: 'Not Readable',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonScanDL',
      text: 'Scan DL',
      handler: handleClick,
      cssName: 'test-button-up'
    },
    {
      id: 'buttonDisconnect',
      text: 'Disconnect',
      handler: handleClick,
      cssName: 'test-button-up'
    }
  ]

  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1000, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const animationScanDoc = themeContext.ImageScanDocument

  const animationSection = animationScanDoc ? (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${animationScanDoc}`}
      width={animationSize.width}
      height={animationSize.height}
      //isVideo={true}
    />
  ) : (
    <>
      <PageSubContent flexFlow={'row'} padding={'0'}>
        <PageContent padding={isLandscape ? '0 20px' : '0 5px'}>
          <DynamicImage imageName={'scan-card.png'} width={isLandscape ? 447 : 335} height={isLandscape ? 320 : 240} />
          <PageSubContent width={isLandscape ? '447px' : '335px'} background={'grey'} padding={'0'}>
            <PageTitle minHeight={isLandscape ? '60px' : '80px'} fontSize={'18px'} color={'white'}>
              {intl.formatMessage(messages.ScanCard_Title)}
            </PageTitle>
          </PageSubContent>
        </PageContent>
        <PageContent padding={isLandscape ? '0 20px' : '0 5px'}>
          <DynamicImage
            imageName={'scan-passport.png'}
            width={isLandscape ? 447 : 335}
            height={isLandscape ? 320 : 240}
          />
          <PageSubContent width={isLandscape ? '447px' : '335px'} background={'grey'} padding={'0'}>
            <PageTitle minHeight={isLandscape ? '60px' : '80px'} fontSize={'18px'} color={'white'}>
              {intl.formatMessage(messages.ScanPassport_Title)}
            </PageTitle>
          </PageSubContent>
        </PageContent>
      </PageSubContent>
    </>
  )
  // const textSection = (
  //   <>
  //     <PageTitle>
  //       {intl.formatMessage(messages.ScanDocs_Body, {
  //         passengerSequence: currentPassenger
  //           ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
  //           : getScanSequence(validatePaxResult)
  //       })}
  //     </PageTitle>
  //   </>
  // )

  // let message = docRetryStatus
  //   ? intl.formatMessage(messages.ScanDocs_Retry, {
  //       passengerSequence: currentPassenger
  //         ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
  //         : getScanSequence(validatePaxResult)
  //     })
  //   : intl.formatMessage(messages.ScanDocs_Body, {
  //       passengerSequence: currentPassenger
  //         ? `${currentPassenger.firstName} ${currentPassenger.lastName}`
  //         : getScanSequence(validatePaxResult)
  //     })

  let message = docRetryStatus ? (
    intl.formatMessage(messages.ScanDocs_Retry)
  ) : (
    <FormattedMessage
      {...messages.ScanDocs_Title}
      values={{
        b: (...chunks) => <div>{chunks}</div>,
        paxName: <span className="scan-document-title">{travelerName}</span>,
        br: <Spacer />
      }}
    />
  )
  //: intl.formatMessage(messages.ScanDocs_Title, { paxName: travelerName })

  //let accMessage = docRetryStatus ? formatMessage(messages.ScanDocs_Retry) : formatMessage(messages.ScanDocs_SubTitle)

  const textSection = (
    <>
      {/*     <PageTitle
        minHeight={'30px'}
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        alignItems="center"
        color={themeContext.PrimaryFontColor}
      >
        {currentPassenger ? travelerName : ''}
      </PageTitle> */}
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {message}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
      >
        {intl.formatMessage(messages.ScanDocs_SubTitle)}
      </PageSubTitle>
    </>
  )

  const footer = (
    <>
      <Footer
        isQuitRequired={true}
        quitBtnText={intl.formatMessage(messages.buttonQuit)}
        isBackRequired={false}
        isSkipRequired={false}
        isConfirmRequired={false}
        testData={testButtons}
      />
    </>
  )

  const popupSection = (
    <>
      {popupContainer.removePassport}
      {popupContainer.retryMediaAccess}
      {popupContainer.processingPassport}
    </>
  )

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ScanDocuments
