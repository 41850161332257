import { LocalActions } from 'constants/Constants'

const initialState = {
  locale: config.defaultLanguage,
  direction: config.languageDir,
  active: false,
  accessibleMode: false,
  scannedBagtag: null,
  bagWeight: 0
}

export function updateLocalData(state = initialState, action) {
  let data = Object.assign({}, state)
  switch (action.type) {
    case LocalActions.SWITCH_LOCALE:
      data.locale = action.locale
      // data.direction = action.locale.direction
      break
    case LocalActions.SWITCH_DIRECTION:
      data.direction = action.direction
      break
    case LocalActions.UPDATE_BARCODE_ATTEMPTS:
      data.errorBarcodeAttempts = action.errorBarcodeAttempts
      break
    case LocalActions.UPDATE_DEVICE_ATTEMPTS:
      data.errorDeviceAttempts = action.errorDeviceAttempts
      break
    case LocalActions.INITIAL_LOCAL_DATA:
      return {
        locale: config.defaultLanguage || state.locale,
        direction: config.languageDir || state.direction,
        active: false,
        accessibleMode: false,
        errorBarcodeAttempts: 0,
        errorDeviceAttempts: 0,
        appFlow: 0,
        BagsDetail: [],
        BagsEntryDetail: [],
        transactionDetail: [],
        errorCodeList: [],
        totalWeight: 0,
        numberOfProcessedBags: 0,
        startTxnNextScreen: '',
        bagProcessStatus: {},
        bagdropMsg: '',
        errorPhotoAttempts: 0,
        errorTravelDocAttempts: 0,
        photoInfoArr: [],
        travelDocArr: [],
        travelDocImage: null,
        errorName: '',
        overrideCode: '',
        scannedData: '',
        mismatchBPError: false,
        bagObj: {},
        carrierCode: '',
        validatePaxResult: [],
        sequence: [],
        currentPassenger: null,
        isLastPassenger: false,
        faceMatchingStage: undefined,
        pooling: false,
        baggageLoadedStatus: false,
        bqCommand: '',
        itineraryObj: {},
        nextBagQuestion: false,
        bagWeight: '0',
        bagWeightUnit: data.bagWeightUnit ? data.bagWeightUnit : 'KG',
        faceServiceUseFor: '', //RECOGNITION OR TAKEPHOTO
        serverTime: new Date().getTime(),
        clientBaseTime: 0,
        scannedBagtag: null,
        sbdScanExtend: null,
        bagtagObject: null,
        bagMeasurements: null
      }
    case LocalActions.ACTIVE:
      data.active = action.active
      break
    case LocalActions.ACCESSIBLE_MODE:
      data.accessibleMode = action.accessibleMode
      break
    case LocalActions.QUIT_REASON:
      data.quitReason = action.quitReason
      break
    case LocalActions.QUIT_DETAIL:
      data.quitDetail = action.quitDetail
      break
    case LocalActions.APP_FLOW:
      data.appFlow = action.appFlow
      break
    case LocalActions.BAGS_DETAILS:
      if (!data.BagsDetail) {
        data.BagsDetail = []
      }
      data.BagsDetail = data.BagsDetail.concat(action.BagsDetail)
      break
    case LocalActions.BAGS_ENTRY_DETAILS:
      data.BagsEntryDetail = action.BagsEntryDetail
      break
    case LocalActions.TRANSACTION_DETAIL:
      if (!data.transactionDetail) {
        data.transactionDetail = []
      }
      data.transactionDetail = data.transactionDetail.concat(action.transactionDetail)
      break
    case LocalActions.UPDATE_ERROR_CODE_LIST:
      if (data.errorCodeList) {
        data.errorCodeList = data.errorCodeList.concat(action.errorCodeList)
      } else {
        data.errorCodeList = action.errorCodeList
      }
      break
    case LocalActions.START_TXN_NEXT_SCREEN:
      data.startTxnNextScreen = action.startTxnNextScreen
      break
    case LocalActions.CLEAR_LOCAL_DATA:
      return ''
    case LocalActions.BAG_MEASUREMENTS:
      data.bagMeasurements = action.bagMeasurements
      break
    case LocalActions.BAGTAG_OBJECT:
      data.bagtagObject = action.bagtagObject
      break
    case LocalActions.PREV_BAG_MEASUREMENTS:
      data.prevBagMeasurements = action.prevBagMeasurements
      break
    case LocalActions.PREV_BAGTAG_OBJECT:
      data.prevBagtagObject = action.prevBagtagObject
      break
    case LocalActions.PREV_BAG_ON_QBELT:
      data.prevBagOnQBelt = action.prevBagOnQBelt
      break
    case LocalActions.BAGTAG:
      data.bagtag = action.bagtag
      break
    case LocalActions.SCANNED_BAGTAG:
      data.scannedBagtag = action.scannedBagtag
      break
    case LocalActions.OVERRIDE_CODE:
      data.overrideCode = action.overrideCode
      break
    case LocalActions.ACTIVATED_BAGTAGS:
      data.activatedBagtags = action.activatedBagtags
      break
    case LocalActions.TOTAL_WEIGHT:
      data.totalWeight = action.totalWeight
      break
    case LocalActions.TOTAL_WEIGHT_LIMIT:
      data.totalWeightLimit = action.totalWeightLimit
      break
    case LocalActions.NUMBER_OF_PROCESSED_BAGS:
      data.numberOfProcessedBags = action.numberOfProcessedBags
      break
    case LocalActions.TICKET:
      data.printStream = action.printStream
      break
    case LocalActions.TICKET_PECTAB:
      data.pectab = action.pectab
      break
    case LocalActions.PRINTDATA_TRANSITION:
      data.printTransition = action.printTransition
      break
    case LocalActions.BAG_PROCESS_STATUS:
      data.bagProcessStatus = {
        statusId: action.statusId,
        checkmark: action.checkmark
      }
      break
    case LocalActions.AEA_BAGDROP_MSG:
      data.bagdropMsg = action.bagdropMsg
      break
    case LocalActions.UPDATE_DOCUMENT_ATTEMPTS:
      data.errorTravelDocAttempts = action.errorTravelDocAttempts
      break
    case LocalActions.UPDATE_PHOTO_ATTEMPTS:
      data.errorPhotoAttempts = action.errorPhotoAttempts
      break
    case LocalActions.UPDATE_PHOTO_INFO:
      data.photoInfoArr[action.photoInfo.travelerOrdinal] = action.photoInfo
      break
    case LocalActions.UPDATE_TRAVEL_DOC:
      data.travelDocArr[action.travelDoc.travelerOrdinal] = action.travelDoc
      break
    case LocalActions.UPDATE_TRAVEL_DOC_IMAGE:
      data.travelDocImage = action.travelDocImage
      break
    case LocalActions.UPDATE_ERROR_NAME:
      data.errorName = action.errorName
      break
    case LocalActions.UPDATE_BARCODE_DATA:
      data.barcodeData = action.barcodeData
      break
    case LocalActions.MISMATCH_BP_ERROR:
      data.mismatchBPError = action.mismatchBPError
      break
    case LocalActions.UPDATE_BAG_OBJ:
      data.bagObj = action.bagObj
      break
    case LocalActions.UPDATE_CARRIER_CODE:
      data.carrierCode = action.carrierCode
      break
    case LocalActions.UPDATE_SERVER_TIME:
      data.serverTime = action.serverTime
      break
    case LocalActions.UPDATE_CLIENT_BASE_TIME:
      data.clientBaseTime = action.clientBaseTime
      break
    case LocalActions.UPDATE_START_TRANSACTION_TIME:
      data.startTransactionTime = action.startTransactionTime
      break
    case LocalActions.VALIDATE_PAX_RESULT:
      data.validatePaxResult = action.validatePaxResult
      break
    case LocalActions.UPDATE_SEQUENCE:
      data.squence = action.squence
      break
    case LocalActions.UPDATE_CURRENT_PASSENGER:
      data.currentPassenger = action.currentPassenger
      break
    case LocalActions.UPDATE_IS_LAST_PASSENGER:
      data.isLastPassenger = action.isLastPassenger
      break
    case LocalActions.UPDATE_FACE_MATCHING_STAGE:
      data.faceMatchingStage = action.faceMatchingStage
      break
    case LocalActions.UPDATE_BAGGAGE_LOADED_STATUS:
      data.baggageLoadedStatus = action.baggageLoadedStatus
      break
    case LocalActions.UPDATE_BQ_COMMAND:
      data.bqCommand = action.bqCommand
      break
    case LocalActions.UPDATE_POOLING:
      data.pooling = action.pooling
      break
    case LocalActions.UPDATE_ITINERARY_OBJ:
      data.itineraryObj = action.itineraryObj
      break
    case LocalActions.UPDATE_NEXT_BAG_QUESTION:
      data.nextBagQuestion = action.nextBagQuestion
      break
    case LocalActions.UPDATE_BAG_WEIGHT:
      data.bagWeight = action.bagWeight
      break
    case LocalActions.UPDATE_BAG_WEIGHT_UNIT:
      data.bagWeightUnit = action.bagWeightUnit
      break
    case LocalActions.TUB_USED:
      data.tubUsed = action.tubUsed
      break    
    default:
      return state
  }
  return data
}
