import { updateLocalData } from 'actions/localActions'
import { Animation } from 'components'
import { BagProcessingInnerSpan, PageSubTitle, PageTitle } from 'components/styledComponents'
import { BaggageStatus } from 'constants/Constants'
import { deviceIds, TraceLevels } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useMedia from 'hooks/useMedia'
import { getBuildAccessibility, getDeviceManager, getSBDAppMan, playSound } from 'main'
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { appLog } from 'utils/Logger'
import { getBags, getPassenger } from 'utils/bagProcessUtils'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { formatBagTagNumber, getScreenId, navigate, populateItineraryInfo } from 'utils/helper'
import { Footer } from '../footer'

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const isQuitRequired = false
const isLangRequired = false
const BagProcessing = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const appFlow = useSelector((state) => state.localData.appFlow)
  const currentPax = useSelector((state) => state.localData.currentPassenger)
  const direction = useSelector((state) => state.localData.direction)
  const bagProcessStatus = useSelector((state) => state.localData.bagProcessStatus)
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const bagtag = useSelector((state) => state.localData.bagtag)
  const prevBagOnQBelt = useSelector((state) => state.localData.prevBagOnQBelt)
  const bqCommand = useSelector((state) => state.localData.bqCommand)
  const locale = useSelector((state) => state.localData.locale)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const initialIndicator = useRef(true)
  const myFlow = useRef(0)
  const previousPrevBagOnQBelt = usePrevious(prevBagOnQBelt)
  const previousBagProcessStatus = usePrevious(bagProcessStatus)
  const [statusArr, setStatusArr] = useState([
    { id: 0, status: 1 },
    { id: 1, status: 0 },
    { id: 2, status: 0 },
    { id: 3, status: -1 },
    { id: 4, status: 0 },
    { id: 5, status: 0 }
  ])
  const [displayFullTable, setDisplayFullTable] = useState(null)
  const title = intl.formatMessage(messages.BagProcessingTitle1)
  const subtitle = intl.formatMessage(messages.BagProcessingSubTitle1)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')
  const processedBagtags = useRef([])
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const location = useLocation()
  const screenId = getScreenId(location.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const itinerary = itineraryInfo ? populateItineraryInfo(itineraryInfo) : undefined

    const accDef = {
      pathName: 'BagProcessing',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        isLangRequired,
        screenId
      }),
      itineraryTextId: itinerary ? 'ItineraryInfoWithBaggageWeight' : 'BaggageWeight',
      itineraryTextParameters: itinerary
        ? [itinerary.displayName, itinerary.departureCity, itinerary.arrivalCity, `${bagWeight} ${bagWeightUnit}`]
        : [`${bagWeight} ${bagWeightUnit}`],
      sequenceDef: {
        sequence: [{ id: 'page-content', textId: 'TwoDynamicText', textParameters: [title, subtitle] }]
      }
    }
    buildAccessibility(accDef)
  }

  const handleActions = (e) => {
    appLog(
      TraceLevels.LOG_EXT_TRACE,
      '(BagProcessing.js) handleActions() - e.currentTarget.id = "' + e.currentTarget.id + '"'
    )
    playSound.beepOK()
    let bagTag = ''

    let unActiveBags = null
    let bagMeasurements = null
    let bags = getBags(itineraryInfo)
    console.log('bags:', bags)
    if (bags && bags.length > 0) {
      unActiveBags = bags.filter((bag) => {
        return bag.status !== BaggageStatus.ACTIVATED
      })
    }
    switch (e.currentTarget.id) {
      case 'buttonWeight':
        dispatch(updateLocalData('appFlow', 5))
        /* aeaBagdrop.onDeviceEvent({
          key: 'unsolicitedMessage',
          value: 'HDCSQNI#OS=0#BC=0#CB=5#SW=0#BB=0#BS=1#CA=0#SD=INTR'
        })
         */

        if (unActiveBags && unActiveBags.length > 0) {
          bagTag = unActiveBags[0].baseTagNo
          const currentPassenger = getPassenger(itineraryInfo, unActiveBags[0].paxOrdinal)
          if (currentPassenger) {
            dispatch(updateLocalData('updateCurrentPassenger', currentPassenger))
          }
        }
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          // value: 'HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#CA=0#SD=BAGP'
          // value: 'HDCBQOK#LP=' + bagTag + '#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=1.9KG#BT=0#BS=BAGP'
          //value: `HDCBQOK#LP=${bagTag}#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=1.9KG#BT=0#BS=BAGP`
          //value: `HDCBQOK#LP=${bagTag}#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=12.6KG#BT=0#BS=BAGP`
          value: `HDCBQOK#LP=${bagTag}#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=12.6${bagWeightUnit}#BT=0#BS=BAGP`
        })

        /* setTimeout(() => {
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCPROK#R0'
          })
        }, 2000) */
        break
      case 'buttonHeavy':
        bags = getBags(itineraryInfo)
        console.log('bags:', bags)
        if (bags && bags.length > 0) {
          unActiveBags = bags.filter((bag) => {
            return bag.status !== BaggageStatus.ACTIVATED
          })
        }
        if (unActiveBags && unActiveBags.length > 0) {
          bagTag = unActiveBags[0].baseTagNo
        }
        bagTag = bags[0].baseTagNo
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value:
            'HDCBQOK#LP=' +
            bagTag +
            '#LP=0000011111' +
            `#BC=1#HT=22CM#WT=40CM#LT=54CM#SW=4#CW=24.2${bagWeightUnit}#BT=0#BS=BAGP`
          //`#BC=1HEAVY#HT=22CM#WT=40CM#LT=54CM#SW=4#CW=24.2${bagWeightUnit}#BT=0#BS=BAGP`
        })
        setTimeout(() => {
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCPROK#R0'
          })
        }, 2000)
        break
      case 'buttonOverWeight':
        bags = getBags(itineraryInfo)
        console.log('bags:', bags)
        if (bags && bags.length > 0) {
          unActiveBags = bags.filter((bag) => {
            return bag.status !== BaggageStatus.ACTIVATED
          })
        }
        if (unActiveBags && unActiveBags.length > 0) {
          bagTag = unActiveBags[0].baseTagNo
        }
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCBQOK#LP=' + bagTag + `#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=148.0${bagWeightUnit}#BS=BAGP`
        })
        break
      case 'buttonAccept':
        // dispatch(setBagProcessStatus(5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCPROK#R0'
        })
        break
      case 'buttonOther':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: `HDCBQOK#LP=${config.testData[2].bagtag[0]}#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=14.0${bagWeightUnit}#BS=BAGP`
        })
        break
      case 'buttonIntrusion':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'unsolicitedMessage',
          value: 'HDCSQNI#OS=0#BC=0#CB=5#SW=0#BB=0#BS=1#CA=0#SD=INTR'
        })
        break
      case 'buttonBagJam':
        dispatch(updateLocalData('appFlow', 6))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#BAGJ'
        })
        break
      case 'buttonTooTall':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVHG'
        })
        break
      case 'buttonMultiTags':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: `HDCBQOK#LP=0220123456#HT=25CM#WT=45CM#LT=56CM#SW=4#CW=23.8${bagWeightUnit}#LP=0220123457#HT=35CM#WT=55CM#LT=56CM#SW=4#CW=32.1KG`
        })
        break
      case 'buttonTooLong':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVLT'
        })
        break
      case 'buttonMaxWeight':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVWT'
        })
        break
      case 'buttonCannotRead':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#BGMV'
        })
        break
      case 'buttonTooShort':
        dispatch(updateLocalData('appFlow', 6))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVSH'
        })
        break
      case 'buttonEstop':
        dispatch(updateLocalData('appFlow', 2))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCSQOK#OS=0#BC=0#CB=0#SW=0#BB=0#BS=1#CA=0#SD=EMCY'
        })
        break
      case 'buttonMyTest':
        dispatch(updateLocalData('appFlow', 5))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: `HDCBQOK#LP=9998444151#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=30.2${bagWeightUnit}#BT=0#BS=BAGP`
        })
        /* if (currentPax) {
          if (unActiveBags && unActiveBags.length > 0) {
            let nextBag = unActiveBags.find((bag) => {
              return bag.paxOrdinal !== currentPax.ordinal
            })
            if (nextBag) {
              let newCurrentPassenger = getPassenger(itineraryInfo, nextBag.paxOrdinal)
              if (newCurrentPassenger) {
                dispatch(updateLocalData('updateCurrentPassenger', newCurrentPassenger))
              }
              dispatch(updateLocalData('appFlow', 5))
              aeaBagdrop.onDeviceEvent({
                key: 'commandCompleted',
                value: `HDCBQOK#LP=${nextBag.baseTagNo}#HT=24CM#WT=51CM#LT=64CM#SW=4#CW=30.4${bagWeightUnit}#BT=0#BS=BAGP`
              })
            }
          }
        } */
        break
      case 'buttonUseBin':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#OVFL'
        })
        break
      case 'buttonMultiBags':
        dispatch(updateLocalData('appFlow', 4))
        aeaBagdrop.onDeviceEvent({
          key: 'commandCompleted',
          value: 'HDCERR5#TOMB'
        })
    }
  }

  // update status array
  useEffect(() => {
    if (previousBagProcessStatus !== bagProcessStatus || previousPrevBagOnQBelt !== prevBagOnQBelt) {
      let newStatusArr = statusArr.map((ele) => {
        let status = ele.status
        if (ele.id === 3) {
          status = prevBagOnQBelt === true && ele.status < 0 ? 0 : ele.status
        } else if (ele.id < bagProcessStatus.statusId) {
          status = 2
        } else if (ele.id === bagProcessStatus.statusId) {
          status = bagProcessStatus.checkmark == true ? 2 : 1
        }
        return { id: ele.id, status: status }
      })

      setStatusArr(newStatusArr)
    }
  }, [bagProcessStatus, statusArr, prevBagOnQBelt])

  // generate display information
  useEffect(() => {
    let displayArr = []

    if (!config.BagProcessingValidating) {
      // display bag processing details

      const generateProcessDetail = (processElement) => {
        let checkColumn,
          messageColumn = null
        switch (processElement.status) {
          case 0:
            checkColumn = (
              <div className="emb-BagTags-td-checkMark">
                <div className="bagProcessing_checkmark_before" />
              </div>
            )
            messageColumn = (
              <div className="emb-BagTags-td" style={{ textAlign: direction !== 'rtl' ? 'right' : 'left' }}>
                <div
                  className="bagProcessing-text-before"
                  style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}
                >
                  <BagProcessingInnerSpan>{processElement.text}</BagProcessingInnerSpan>
                </div>
              </div>
            )
            break
          case 1:
            checkColumn = (
              <div className="emb-BagTags-td-checkMark">
                <div className="bagProcessing-checkmark-current" />
              </div>
            )

            messageColumn = (
              <div className="emb-BagTags-td" style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}>
                <div
                  className="bagProcessing-text-current"
                  style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}
                >
                  <BagProcessingInnerSpan>{processElement.text}</BagProcessingInnerSpan>
                </div>
              </div>
            )
            break
          case 2:
            checkColumn = (
              <div className="emb-BagTags-td-checkMark">
                <div className="bagProcessing-checkmark-after" />
              </div>
            )

            messageColumn = (
              <div className="emb-BagTags-td" style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}>
                <div className="bagProcessing-text-after" style={{ textAlign: direction !== 'rtl' ? 'left' : 'right' }}>
                  <BagProcessingInnerSpan>{processElement.text}</BagProcessingInnerSpan>
                </div>
              </div>
            )
            break
          case -1:
          default:
            break
        }
        return [checkColumn, messageColumn]
      }

      statusArr.forEach((statusElement, i) => {
        let statusName
        switch (statusElement.id) {
          case 0:
            statusName = intl.formatMessage(messages.BagProcessingStep1)
            break
          case 1:
            statusName = intl.formatMessage(messages.BagProcessingStep2)
            break
          case 2:
            statusName = `${intl.formatMessage(messages.BagProcessingStep3)} ${
              bagtag == null ? '' : formatBagTagNumber(bagtag)
            }`
            break
          case 3:
            statusName = intl.formatMessage(messages.BagProcessingStep4)
            break
          case 4:
            statusName = intl.formatMessage(messages.BagProcessingStep5)
            break
          case 5:
            statusName = intl.formatMessage(messages.BagProcessingStep6)
            break
          default:
            break
        }
        const statusObj = {
          id: statusElement.id,
          text: statusName,
          status: statusElement.status
        }
        const [checkColumn, messageColumn] = generateProcessDetail(statusObj)

        if (messageColumn !== null) {
          displayArr.push(
            <div key={i} className="emb-BagTags-tr" id={'BagProcessingStep' + i} tabIndex="0">
              {checkColumn}
              {messageColumn}
            </div>
          )
        }
      })

      setDisplayFullTable(<div className="emb-BagTags-table">{displayArr}</div>)
    }
  }, [statusArr, bagtag])

  // update sbd belt communication
  useLayoutEffect(() => {
    if (initialIndicator.current) {
      if (appFlow !== 5) {
        dispatch(updateLocalData('bagtag', null))
      }
      if (bqCommand && bqCommand !== '' && appFlow !== 5) {
        navigate('PleaseWait', 5)
        getSBDAppMan().cc_5(bqCommand)
      } else if (appFlow === 2) {
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Enable SBD via getSBDAppMan...')
        getSBDAppMan().setSQStart(-1)
        getSBDAppMan().setPutBagStart(new Date().getTime())

        //DeviceActions.callDevice(AEA_BAGDROP, 'enable')

        if (config.callCR == 'Y' && config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (BagProcessing.js) componentDidMount() - Enable Scanner CR')
          //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CR', -10000)
          aeaBagdrop.sendAEA('CR', -10000)
        }

        // check if bag is there - delay for config.minPutBag*1000
        //state.set("PutBagStart", (new Date()).getTime()

        if (config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (BagProcessing.js) componentDidMount() - send SQ')
          aeaBagdrop.sendAEA('SQ', -10000)
        }
      } else if (appFlow == 3) {
        // load to visible area  CC#PA
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Start appFlow: 3 --> call CC#PA')
        dispatch(updateLocalData('appFlow', 4))
        //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CC#PA', -15000)
        //      aeaBagdrop.sendAEA('CC#PA', -15000)
        getSBDAppMan().sendAEACommand('CC#PA', config.loadTimeout, 4, 'bagProcessing')
        appLog(TraceLevels.LOG_EXT_TRACE, 'Bag processing CC#PA')
      }

      initialIndicator.current = false
    } else {
      if (appFlow === 2) {
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Enable SBD via getSBDAppMan...')
        getSBDAppMan().setSQStart(-1)
        getSBDAppMan().setPutBagStart(new Date().getTime())

        //DeviceActions.callDevice(AEA_BAGDROP, 'enable')

        if (config.callCR == 'Y' && config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (BagProcessing.js) componentDidMount() - Enable Scanner CR')
          //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CR', -10000)
          aeaBagdrop.sendAEA('CR', -10000)
        }

        // check if bag is there - delay for config.minPutBag*1000
        //state.set("PutBagStart", (new Date()).getTime()

        if (config.isCUSSRequired) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (BagProcessing.js) componentDidMount() - send SQ')
          aeaBagdrop.sendAEA('SQ', -10000)
        }
      } else if (appFlow == 3) {
        // load to visible area  CC#PA
        appLog(TraceLevels.LOG_EXT_TRACE, '(BagProcessing.js) componentDidMount() - Start appFlow: 3 --> call CC#PA')
        dispatch(updateLocalData('appFlow', 4))
        //DeviceActions.getResponse(AEA_BAGDROP, 'sendAEA', 'sendAEA_RC', 'CC#PA', -15000)
        //      aeaBagdrop.sendAEA('CC#PA', -15000)
        getSBDAppMan().sendAEACommand('CC#PA', config.loadTimeout, 4, 'bagProcessing')
        appLog(TraceLevels.LOG_EXT_TRACE, 'Bag processing CC#PA')
      }
    }
  }, [appFlow, bqCommand])

  // end if (bag processing details

  let displayData = config.showBagProcessingDetails ? (
    displayFullTable
  ) : (
    <div className="bagProcessing-infoText-wrapper">
      <div className="bagProcessing-infoText1">{intl.formatMessage(messages.BagProcessingValidating)}</div>
      <div className="bagProcessing_infoText2">
        {intl.formatMessage(messages.BagProcessingValidatingBagtag, { bagtag: formatBagTagNumber(bagtag) })}
      </div>
    </div>
  )

  let testButtons = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonWeight',
      text: 'process',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonOther',
      text: 'not scanned',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonHeavy',
      text: 'Heavy bag',
      handler: handleActions,
      group: 0
    },
    {
      id: 'buttonOverWeight',
      text: 'Over weight',
      handler: handleActions,
      group: 0
    },
    {
      id: 'ERROR-DESC',
      text: 'Error Cases:',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonIntrusion',
      text: 'Intrusion',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonBagJam',
      text: 'Bag Jam',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonTooTall',
      text: 'Too Tall',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMultiTags',
      text: 'Multi Tags',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonTooLong',
      text: 'Too Long',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMaxWeight',
      text: 'Max Weight',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonCannotRead',
      text: 'Cannot weigh',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonTooShort',
      text: 'Too Flat',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonEstop',
      text: 'E STOP',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMyTest',
      text: 'next pax',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonUseBin',
      text: 'Use Bin',
      handler: handleActions,
      group: 1
    },
    {
      id: 'buttonMultiBags',
      text: 'Multi Bags',
      handler: handleActions,
      group: 1
    }
  ]
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const animationBagProcess = isLandscape
    ? themeContext.AnimationLandscape.BagProcess
    : themeContext.AnimationPortrait.BagProcess

  const titleAlignment = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const tableAlignment = useMedia(null, ['center', 'center', 'flex-end'])
  const animationSection = (
    <Animation
      imageName={`${themeContext.AnimationPath}/${sbdModel}/${animationBagProcess}`}
      width={animationSize.width}
      height={animationSize.height}
    />
  )
  const textSection = (
    <>
      <PageTitle
        alignItems="center"
        fontSize={themeContext.PageTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageTitle.fontWeight}
      >
        {title}
      </PageTitle>
      <PageSubTitle
        fontSize={themeContext.PageSubTitle.fontSize[ratioKey]}
        fontWeight={themeContext.PageSubTitle.fontWeight}
        paddingTop="20px"
      >
        {subtitle}
      </PageSubTitle>
    </>
  )

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      isBackRequired={false}
      isSkipRequired={false}
      isLangRequired={isLangRequired}
      isHelpRequired={false}
      testData={testButtons}
    />
  )

  // let contentWidth = isLandscape ? '50%' : '90%'
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { header: null, topSection: textSection, bottomSection: animationSection, footer },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default BagProcessing
