import { faceTrackingOnEvent } from 'devices/callbacks'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import { getDeviceManager } from 'main'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { appLog } from 'utils/Logger'
import { EventFlows, EventFunctions, logEvents, sessionStart } from 'utils/appEventLogger'
import { getObjFromXml, toBoolean } from 'utils/helper'

/* import {
  FaceServiceAction,
  FaceServiceValue,
  PhotoDetectionInfo,
  ImageParserResult,
  HookFaceServiceEventHandler,
  HookEventCallback,
  isHookEventCallback
} from './types' 

export interface ImageParserResult {
  capturedPhoto: string
  compareScore: number | null
  photoInfo: PhotoDetectionInfo | undefined
} */
export function isHookEventCallback(handler) {
  return typeof handler.callbackHandler === 'function'
}
export const eventToImage = (event) => {
  let captureResult = event.value.split(',')
  let photoInfo = undefined
  let capturedPhoto = ''
  let compareScore = null
  switch (event.key) {
    case 'analyzeResult':
      capturedPhoto = captureResult[0].trim()
      compareScore = parseFloat(captureResult[3].trim())
      break
    case 'faceDescriptor':
      photoInfo = captureResult?.[3]
        ? getObjFromXml(`<PhotoDetectionInfo>${captureResult[3]}</PhotoDetectionInfo>`)?.PhotoDetectionInfo
        : undefined
      capturedPhoto = toBoolean(photoInfo?.SpoofedFaceImage) ? null : captureResult[1].trim()
      compareScore = captureResult[2].trim() !== '' ? parseFloat(captureResult[2].trim()) : null
      break
  }

  return {
    capturedPhoto: capturedPhoto,
    compareScore: compareScore,
    photoInfo: photoInfo
  }
}

export const analyzeEventToImage = (event) => {
  let compareScore = ''
  const analyzeResult = event.value

  compareScore = analyzeResult[0] ? getObjFromXml(analyzeResult[0])?.Score : undefined

  return {
    capturedPhoto: null,
    compareScore: compareScore,
    photoInfo: null
  }
}

export const baseFaceServiceSetup = {
  showLiveStream: true,
  enableTimer: true,
  faceCaptureTimeout: 10,
  defaultLiveURL: null,
  faceTrackingMode:
    '<PreviewCentered>False</PreviewCentered><PreviewHeight>320</PreviewHeight><PreviewWidth>220</PreviewWidth><PreviewXPos>275</PreviewXPos><PreviewYPos>570</PreviewYPos>',
  bestShotTime: '<BestShotTime>10</BestShotTime>',
  imageParser: eventToImage
}

const useBaseFaceService = (handler, setup = baseFaceServiceSetup) => {
  const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
  const [liveStreamUrl, setLiveStreamUrl] = useState(setup.defaultLiveURL)
  const [faceAppear, setFaceAppear] = useState(false)
  const captureTimerRef = useRef()
  const faceServiceUsedFor = useSelector((state) => state.settings.faceServiceUsedFor)
  let faceTrackingCallback = (event) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTrackingOnEvent() is called: ' + event.key + ' value: ' + event.value)
    if (!isHookEventCallback(handler)) {
      switch (event.key) {
        case 'facePresent':
          appLog(TraceLevels.LOG_TRACE, 'Event: facePresent: ' + event.value)
          setFaceAppear(true)
          if (faceServiceUsedFor === 'RECOGNITION') {
            sessionStart()
          }
          logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureDetected, 'Detected')
          break
        case 'faceAbsent':
          appLog(TraceLevels.LOG_TRACE, 'Event: faceAbsent: ' + event.value)
          setFaceAppear(false)
          break
        case 'pictureTaken':
          break
        case 'analyzeResult':
          if (captureTimerRef.current) {
            clearTimeout(captureTimerRef.current)
            captureTimerRef.current = undefined
          }
          const analyzeResultImageData = setup.imageParser(event)
          if (analyzeResultImageData.capturedPhoto === 'null') {
            handler.handleError('enable', null)
          } else {
            handler.handleSuccess(analyzeResultImageData)
          }
          break
        case 'faceDescriptor':
          if (captureTimerRef.current) {
            clearTimeout(captureTimerRef.current)
            captureTimerRef.current = undefined
          }
          const faceDescriptorImageData = setup.imageParser(event)
          if (faceDescriptorImageData.capturedPhoto === 'null') {
            handler.handleError('enable', null)
          } else {
            handler.handleSuccess(faceDescriptorImageData)
          }
          break
        case 'analyze':
          const analyzeImageScore = analyzeEventToImage(event)
          handler.handleSuccess(analyzeImageScore)
          break
        case 'enable':
          if (event.value !== '0') {
            handler.handleError('enable', null)
            // handleRetry(null, 0)
          } else {
            if (setup.showLiveStream) {
              faceTrackingGetStreamingURL()
            }

            if (setup.enableTimer) {
              startTimmer()
            }
            logEvents(EventFlows.FaceCapture, EventFunctions.FaceCaptureStart, 'Initiated')
          }
          break
        case 'show':
          break
        case 'hide':
          break
        case 'getStreamingURL':
          if (event.value !== '') {
            setLiveStreamUrl(event.value)
          } else {
            faceTracking.show(setup.faceTrackingMode)
            faceTracking.focusApp()
          }
          break
        case 'dataError':
          logEvents(EventFlows.FaceMatchN, EventFunctions.FaceMatchError, 'Face match error, data error')
          if (captureTimerRef.current) {
            clearTimeout(captureTimerRef.current)
            captureTimerRef.current = undefined
          }
          handler.handleError('dataError', null)
          break
        case 'switchView':
        case 'toggleView':
          // switchView: 0,<CurrentView>0</CurrentView><PrimaryView>0</PrimaryView>
          // toggleView: 0
          // or:         302
          appLog(TraceLevels.LOG_TRACE, 'Event: switch/toggle View rc: ' + event.value)
          let rc = ('' + event.value).split(',')
          if (rc[0] !== '0') {
            // error
            if (captureTimerRef.current) {
              clearTimeout(captureTimerRef.current)
              captureTimerRef.current = undefined
            }
            handler.handleError('toggleView', null)
          }
          break
        default:
          appLog(
            TraceLevels.LOG_TRACE,
            'Event: unhandled event, this should not happen, event key : ' + event.key + ' event value' + event.value
          )
          break
      }
    }
  }

  const startTimmer = () => {
    captureTimerRef.current = setTimeout(() => {
      if (!isHookEventCallback(handler)) {
        handler.handleError('timeout', null)
      }
      // failHandler(null, 0, KIOSK_PHOTO_RESULT_CODE.TIMEOUT)
    }, setup.faceCaptureTimeout * 1000)
  }

  useEffect(() => {
    return () => {
      if (captureTimerRef.current !== undefined) {
        clearTimeout(captureTimerRef.current)
        captureTimerRef.current = undefined
      }
    }
  }, [])

  // device call put in object action
  const faceServiceEnable = (paxDocImage) => {
    console.log('enable face service')
    if (faceTracking) {
      if (isHookEventCallback(handler)) {
        faceTrackingCallback = handler.callbackHandler
      } else {
        faceTracking.OnDeviceEvent = faceTrackingCallback
      }

      if (paxDocImage) {
        faceTracking.enable(setup.bestShotTime, paxDocImage)
      } else {
        faceTracking.enable(setup.bestShotTime)
      }
    } else {
      console.log('face tracking device is not available.')
    }
  }

  const faceServiceDisable = () => {
    console.log('disable face tracking...')
    if (captureTimerRef.current !== undefined) {
      clearTimeout(captureTimerRef.current)
      captureTimerRef.current = undefined
    }
    if (faceTracking) {
      faceTracking.hide()
      faceTracking.disable()
      faceTracking.OnDeviceEvent = faceTrackingOnEvent
    } else {
      console.log('face tracking device is not available.')
    }
  }

  const cameraToggle = () => {
    appLog(TraceLevels.LOG_TRACE, 'camera Toggle')
    faceTracking.toggleView()
  }

  const faceTrackingGetStreamingURL = () => {
    if (faceTracking) {
      faceTracking.getStreamingURL()
    }
  }

  const faceServiceAnalyze = (image1, image2) => {
    if (faceTracking) {
      if (isHookEventCallback(handler)) {
        faceTrackingCallback = handler.callbackHandler
      } else {
        faceTracking.OnDeviceEvent = faceTrackingCallback
        console.log('=====> setup callback')
      }

      if (image1 && image2) {
        faceTracking.analyze('2', image1, image2)
        console.log('=====> image1 ', image1, '=====> image2', image2)
      }
    }
  }

  const action = {
    enable: faceServiceEnable,
    disable: faceServiceDisable,
    toggle: cameraToggle,
    getStreamURL: faceTrackingGetStreamingURL,
    analyze: faceServiceAnalyze
  }

  // value passing from base hook
  const value = {
    faceAppear: faceAppear,
    url: liveStreamUrl
  }

  return [action, value, faceTrackingCallback]
}

export default useBaseFaceService
