import React, { useState, Fragment, useContext, useRef } from 'react'
import { Button, IconButton } from 'components'
import QuitTxn from './QuitTxn'
import { ThemeContext } from 'styled-components'
import { playSound, getSBDAppMan, getAccessibilityDevice, getAccessibilityManager } from 'main'
import { END_TXN_REASON } from '../../constants/Constants'
import PropTypes from 'prop-types'
import { Spacer } from 'components/styledComponents'
import { useIntl } from 'react-intl'
import useMedia from 'hooks/useMedia'

const QuitAct = (props) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const themeContext = useContext(ThemeContext)
  const [visible, setVisible] = useState(false)
  const popupTimer = useRef(null)
  const quitAction = props.quitAction || null
  const quitBtnText = props.quitBtnText || 'Quit'
  const quitBtnStyle = props.quitBtnStyle || 'btn-quit-up'
  const accMgr = getAccessibilityManager()

  const screenMessage = `${formatMessage(messages.CancelTxnTitle)}. ${formatMessage(messages.CancelTxnSubTitle)}`
  const accDef = {
    startIndex: 0,
    sequenceDef: {
      type: 'POPUP',
      sequence: [
        { id: 'quitLabel', textId: 'OneDynamicText', textParameters: [screenMessage] },
        { id: 'quitNo', text: formatMessage(messages.buttonQuitNo), buttonId: 'quitNo' },
        { id: 'quitYes', text: formatMessage(messages.buttonQuitYes), buttonId: 'quitYes' }
      ]
    }
  }

  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const handleQuitAction = () => {
    playSound.beepOK()
    startPopupTimer()
    setVisible(true)
    accMgr.buildPopupGroup(true, accDef)
    if (props.quitAction) {
      props.quitAction('quitAction')
    }
  }

  const popupCallback = (answer) => {
    playSound.beepOK()
    clearPopupTimer()
    if (answer === 'YES') {
      console.log('answer is yes')
      getSBDAppMan().doQuit(END_TXN_REASON.USER_QUIT, '')
      if (props.quitAction) {
        props.quitAction('callback', 'YES')
      }
    } else {
      console.log('answer is NO')
      if (props.quitAction) {
        props.quitAction('callback', 'NO')
      }
    }
    setVisible(false)
    accMgr.buildPopupGroup(false)
  }

  const startPopupTimer = () => {
    popupTimer.current = setTimeout(() => {
      popupCallback('NO')
    }, config.timeoutPopup * 1000)
    console.log(
      'QuitTxn startPopupTimer() ... START POPUP Timer ... ',
      popupTimer,
      ' ... duration ... ',
      config.timeoutPopup
    )
  }

  const clearPopupTimer = () => {
    if (popupTimer.current !== null) {
      clearTimeout(popupTimer.current)
      console.log('QuitTxn clearPopupTimer() ... STOP POPUP Timer ... ', popupTimer.current)
      popupTimer.current = null
    }
  }

  return (
    <Fragment>
      <QuitTxn visible={visible} popupCallback={popupCallback} buttonGap={'50px'} />
      <IconButton
        id={'quitBtn'}
        cssName={'quit-btn'}
        color={themeContext.QuitButton.color}
        bgColor={themeContext.QuitButton.bgColor}
        width={themeContext.QuitButton.width[ratioKey]}
        height={themeContext.QuitButton.height[ratioKey]}
        iconLocation={themeContext.QuitButton.buttonPosition}
        buttonPosition={themeContext.QuitButton.buttonPosition}
        padding={themeContext.QuitButton.padding}
        //disable={props.disableAction}
        onClick={handleQuitAction}
        fontSize={themeContext.QuitButton.fontSize[ratioKey]}
        text={quitBtnText ? quitBtnText : formatMessage(messages.buttonQuit)}
        icon={themeContext.QuitButton.icon}
        iconColor={themeContext.QuitButton.iconColor}
        disableShadow={themeContext.QuitButton.disableBoxShadow}
      />
    </Fragment>
  )
}
QuitAct.propTypes = {
  quitBtnText: PropTypes.string,
  quitBtnStyle: PropTypes.string,
  quitAction: PropTypes.func
}

export default QuitAct
