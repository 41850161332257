/***************************************************************************************
 * this is first screen of this application, it start with the following scenarios:
 * 1. single app without attract loop (Dedicated or Persistent Single-application Mode)
 * 2. multi app mode active by transfer from other app (bio-matrix), receive pnr number
 * 3. multi app or single app mode with attract loop
 *
 ***************************************************************************************/
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { startEtsTransaction } from 'actions/etsTransactions/sessionAct'
import { Animation, Button, Popup } from 'components'
import { PageContent, PageSubContent, PageSubTitle, PageTitle } from 'components/styledComponents'
import { ETS_TXN_STATUS, TYPE_KIOSK } from 'constants/Constants'
import { TraceLevels, deviceIds } from 'embross-device-manager'
import useUIBase from 'hooks/ui/useUIBase'
import useBarcodeScanner from 'hooks/useBarcodeScanner'
import useCheckLandscape from 'hooks/useCheckLandscape'
import useMedia from 'hooks/useMedia'
import { Footer } from 'layouts/footer'
import {
  getBuildAccessibility,
  getDeviceManager,
  getSBDAppMan,
  getTSDManager,
  getVersion,
  history,
  playSound,
  store
} from 'main'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { EventFlows, EventFunctions, logEvents, sessionStart } from 'utils/appEventLogger'
import { startCUSSTransaction } from 'utils/appTransactions'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { generateBP, getCarrierCodeFromBp, getConveyorPosition, getScreenId, maskPCI } from 'utils/helper'
import { appLog } from 'utils/Logger'
import { updateCustomData } from 'custom/actions/customActions'
import { updateLocalData } from 'actions/localActions'

import { appMgrOnEvent } from 'devices/callbacks'

const displayItinerary = false
const Welcome = () => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const textAlign = useMedia(null, ['flex-start', 'flex-start', 'center'])
  const locale = useSelector((state) => state.localData.locale)
  const manualScanMode = useSelector((state) => state.deviceInfo.manualScanMode)
  const location = useLocation()
  const dispatch = useDispatch()
  const etsTxnStatus = useSelector((state) => state.sessions.ETSTxnStatus)
  const transferData = useSelector((state) => state.sessions.transferData)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const kioskType = useSelector((state) => state.kioskInfo.KIOSK_TYPE)
  const sbdModel = useSelector((state) => state.kioskInfo.SBD_MODEL)
  const ActiveStage = useSelector((state) => state.kioskInfo.onActive)
  const faceMatchingStage = useSelector((state) => state.localData.faceMatchingStage)
  const faceMatchingPrevStage = useRef(null)
  const firstTimeIndicator = useRef(true)
  //const airportCode = useSelector(state => state.kioskInfo.airportCode)
  const aeaBagdrop = getDeviceManager().getDevice(deviceIds.AEA_BAGDROP)
  const isLandscape = useCheckLandscape()
  const tsdMgr = getTSDManager()
  const [visible, setVisible] = useState(false)
  const [input, setInput] = useState('')
  const isQuitRequired = config.firstScreen !== 'welcome'

  console.log('kioskType:', JSON.stringify(kioskType), ' sbdModel:', sbdModel)
  const scannedCallback = (scannedValue) => {
    if (config.isBoardingpassLookupEnable || manualScanMode) {
      sessionStart()
    }
    if (manualScanMode) {
      dispatch(updateLocalData('scannedBagtag', scannedValue))
    } else {
      tsdMgr.carrierCode = getCarrierCodeFromBp(scannedValue)
    }

    logEvents(EventFlows.ScanBCBP, EventFunctions.ScanBCBPResult, maskPCI(scannedValue, deviceIds.BARCODE_READER))
    if (etsTxnStatus === ETS_TXN_STATUS.ETS_TXN_NONE) {
      if (config.isCUSSRequired) {
        console.log('startCUSSTransaction ...')
        getSBDAppMan().enableISBD()
        startCUSSTransaction()
      }
      if (clientConfig.hostURL) {
        //startEtsTransaction(store, TYPE_KIOSK, getVersion(), { barcodeData: scannedValue}, tsdMgr.sessionId)
        startEtsTransaction(store, TYPE_KIOSK, getVersion(), { barcodeData: scannedValue }, tsdMgr.sessionId)
      }
    } else {
      dispatch(getItinerarySBD({ barcodeData: scannedValue }))
    }
  }
  const [enable, disable, barcodeReaderCallback, popupContainer] = useBarcodeScanner(scannedCallback)

  /* const sendResultCallback = (value) => {
    appLog(TraceLevels.LOG_TRACE, 'faceTracking send result callback is called, value: ' + value)
    sendPhotoMatch(value.split(',')[1])
  }

  const [faceTrackingEnable, faceTrackingDisable, faceTrackingShow, faceTrackingHide, faceTrackingCallback] =
    useFaceTracking(sendResultCallback) */

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const handleAccessibility = () => {
    const conveyorPosition = intl.formatMessage(messages[getConveyorPosition()])
    // console.log(conveyorPosition)
    const screenId = getScreenId(location.pathname)
    const screenMessage = `${intl.formatMessage(messages[welcomeTitle])}. ${intl.formatMessage(messages[welcomeMsg])}`
    const accDef = {
      pathName: 'Welcome',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        displayItinerary,
        screenId
      }),
      sequenceDef: {
        sequence: [
          {
            id: 'page-content',
            textId: 'OneDynamicText',
            textParameters: [screenMessage]
          }
        ],
        help: ['HelpBardeLocation', 'HelpBeltLocation']
      }
    }
    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  useEffect(() => {
    if (transferData && transferData.barcodeData) {
      console.log('transferData:', transferData)
      scannedCallback(transferData.barcodeData)
    }
  }, [transferData])

  useEffect(() => {
    firstTimeIndicator.current = false
    return () => {}
  }, [])

  useEffect(() => {
    if (config.isCUSSRequired) {
      appLog(TraceLevels.LOG_TRACE, '(Welcome.js) call barcode enable function')
      if (config.isBoardingpassLookupEnable || manualScanMode) {
        enable()
      }
    }
    return () => {
      if (config.isCUSSRequired) {
        if (config.isBoardingpassLookupEnable || manualScanMode) {
          disable()
        }
      }
    }
  }, [])

  useEffect(() => {
    if (config.isCUSSRequired) {
      if (ActiveStage) {
        if (config.isBagtagLookupEnable) {
          appLog(TraceLevels.LOG_EXT_TRACE, '==> (Welcome.js) componentDidMount() - send SQ')
          getSBDAppMan().setSQStart(-1)
          aeaBagdrop.sendAEA('SQ', -10000)
        }
      }
    }
  }, [ActiveStage])

  //for test buttons

  const id = 'inputId'
  function handlePopupExit() {
    setVisible(false)
    console.log('handlePopupExit clicked')
  }
  const passportDataPopup = (
    <Popup visible={visible} cssDialogName="popup-dialog">
      <PageContent>
        <PageSubContent>
          <label htmlFor={id}>Please input data:</label>
          <textarea id={id} value={input} cols="90" rows="5" type="textare" onInput={(e) => setInput(e.target.value)} />
        </PageSubContent>
        <PageSubContent>
          <Button id="buttonExit" justifyContent="center" onClick={handlePopupExit}>
            Exit
          </Button>
        </PageSubContent>
      </PageContent>
    </Popup>
  )
  const handleActions = (e) => {
    let barcode = ''
    const { pnr, firstName, lastName, from, to, airLine, flightNumber, bagtag } = config.testData[0]
    const SBDAppManager = getSBDAppMan()
    playSound.beepOK()
    appLog(TraceLevels.LOG_EXT_TRACE, '(Welcome.js) handleClick() ... target: ' + e.currentTarget.id)
    if (config.showTestButtons) {
      switch (e.currentTarget.id) {
        case 'buttonScanBarcodeTEST':
          if (config.isBagtagLookupEnable) {
            SBDAppManager.setAppFlow(20)
            aeaBagdrop.onDeviceEvent({
              // key: 'unsolicitedMessage',
              // value: 'HDCBQNI#LP=1581370468#HT=18CM#WT=36CM#LT=37CM#SW=4#CW=0.6KG#BS=BAGP'
              // value: 'HDCBQNI#HT=32CM#WT=43CM#LT=61CM#SW=4#CW=3.6KG#BT=0#BS=BAGP'
              // value: 'HDCBQNI#HT=24CM#WT=44CM#LT=59CM#SW=4#CW=4.0KG#BT=0#BS=BAGP'
              key: 'commandCompleted',
              value:
                'HDCBQOK#LP=' +
                config.testData[0].bagtag +
                '#HT=26CM#WT=54CM#LT=65CM#SW=4#CW=14.7' +
                bagWeightUnit +
                '#BT=0#BS=BAGP'
              // value: 'HDCERR5#INTR'
              //value: 'HDCERR5#TOMB'
              // value: 'HDCPROK#PA'
              // value: 'HDCBQOK#LP=' + bagTag + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
              //value: `HDCBQOK#LP=${bagtag[0]}#HT=36CM#WT=36CM#LT=65CM#SW=4#CW=5.9KG#BT=0#BS=BAGP`
            })
          } else {
            // testPassenger = testPassengers[0]
            barcode = generateBP(pnr, firstName, lastName, from, to, airLine, flightNumber)
            // barcode =
            //   'M1TEST/ONE            E8I9B26 DTWLASE1 1770 213Y13A0 000090F&gt;50B1OO8303BWS 0E83******00000 &gt;460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk=='
            // barcode = `M1TOLHR/ONE           EJD9AB2 DTWJFKDL 5671 352Y010B0001 155>318  W     DL              2900621416096380                           DL3J87FEU5010B^160MEUCIQCE3TVBBSn9RS3tvFsNv8jvAJWTkgienNM7k9/BTh9dLwIgYz37f/TC2nYLColaw2gitlqUJ8ZII9eFLGh4R640FCc=`
            // barcode =
            //   'M1TOSLC/THREEBAGS     EHZKGEM DTWSLCDL 0939 304C002C0001 155>318  W     DL              2900621412918830                           DL3J891CXVT02C^160MEQCIHGVa1E5wahuDpf+dedM2I+Sj7653DRjwKnPo5LFS8BBAiAzddRLFw0CKrAmVFFcyaVh6sFb8iy7miTFVsZeEZ9iQw=='
            //barcode = config.test
            //barcode = 'M1B/B                  PZCNTH HYDBLRI5 1527 176Y038F0001 347&gt;1182 K0143BNK 048702367000229487000000000000'
            //barcode ='M1DTWTOCLT/PAXTWO     EW8QGIU AUHCLTDL 0508 354Y025D0001 155>318  W     WS              2900621403998520                           WS3J8LX7ZES25D'
            // barcode =
            //   'M1SMITH/MARK          EDOM123 YYZYULNK 0123 036Y014K2101 90F>50B1OO8303BWS 0E8380000000000 ^460MEQCIARhOwluxTqLongrJCbgDNGygQ3fk9BGGk1XDkPdJrfzAiBLSzIjq3EHP6CZOxFacKwJ41G2RqsJLvRRREGt4sTFrA==1580928289,1580928311'
            barcodeReaderCallback({
              key: 'barcodeReadInternal',
              value: barcode
            })
          }

          break
        case 'buttonScanBarcodeERROR':
          barcodeReaderCallback({
            key: 'barcodeDamaged',
            value: ''
          })
          break
        case 'buttonFaceMatchTEST':
          // faceTrackingCallback({
          //   key: 'faceDescriptor',
          //   value: config.photoData
          // })
          aeaBagdrop.onDeviceEvent({
            // key: 'unsolicitedMessage',
            // value: 'HDCBQNI#LP=1581370468#HT=18CM#WT=36CM#LT=37CM#SW=4#CW=0.6KG#BS=BAGP',
            key: 'commandCompleted',
            // value: 'HDCERR5#BGPR'
            // value: 'HDCPROK#PA'
            // value: 'HDCBQOK#LP=' + bagTag + '#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0KG#BS=BAGP'
            value: 'HDCBQOK#LP=8006868001#HT=30CM#WT=25CM#LT=60CM#SW=4#CW=5.0' + bagWeightUnit + '#BS=BAGP'
          })
          break
        case 'ScanDocuments':
          history.push('scanDocuments')
          break
        case 'TakePhoto':
          history.push('TakePhoto')
          break
        case 'AgentScan':
          history.push('agentOverride')
          break
        case 'ShowDisclaimer':
          history.push('showDisclaimer')
          break
        case 'PrintBagTag':
          history.push('printBagTag')
          break
        case 'noScan':
          SBDAppManager.setAppFlow(20)
          aeaBagdrop.onDeviceEvent({
            key: 'commandCompleted',
            value: 'HDCERR5#BAGJ'
          })
          break
        case 'putBagOnBelt':
          aeaBagdrop.onDeviceEvent({           
            key: 'commandCompleted',
            value:`HDCBQOK#LP=${bagtag[0]}#HT=36CM#WT=36CM#LT=65CM#SW=4#CW=5.9KG#BT=0#BS=BAGP`
          })
          break  
        case 'customAction':
          dispatch(updateCustomData('updateCustomData', 'custom data'))
          break
        case 'myTest2':
          appMgrOnEvent({
            key: 'notificationText',
            value: 'TRANSFER_INFO={"barcodeData":"M1CHIDDETI/JAGADEEP   E4M5L72 YYZYYCE1 1770 221B025A0012 100"}'
          })
          break
        case 'intrusion':
          aeaBagdrop.onDeviceEvent({
            key: 'unsolicitedMessage',
            value: 'HDCSQNI#OS=0#BC=0#CB=5#SW=0#BB=0#BS=3#CA=0#SD=INTR'
          })
          break
        default:
      }
    }
  }
  const testData = [
    {
      id: 'normalDESC',
      text: 'Normal Cases:',
      group: 0
    },
    {
      id: 'buttonScanBarcodeTEST',
      text: config.isBagtagLookupEnable ? 'Scan bagtag' : 'Scan barcode',
      group: 0,
      handler: handleActions
    },
    // {
    //   id: 'buttonFaceMatchTEST',
    //   text: 'Face match',
    //   cssName: 'test-button-up',
    //   handler: handleActions
    // },
    {
      id: 'ScanDocuments',
      text: 'ScanDoc',
      group: 0,
      handler: handleActions
    },
    {
      id: 'TakePhoto',
      text: 'TakePhoto',
      group: 0,
      handler: handleActions
    },
    {
      id: 'AgentScan',
      text: 'AgentScan',
      group: 0,
      handler: handleActions
    },
    {
      id: 'ShowDisclaimer',
      text: 'ShowDisclaimer',
      group: 0,
      handler: handleActions
    },
    {
      id: 'PrintBagTag',
      text: 'PrintBagTag',
      group: 0,
      handler: handleActions
    },
    {
      id: 'noScan',
      text: 'No Scan',
      group: 0,
      handler: handleActions
    },
    {
      id: 'putBagOnBelt',
      text: 'putBagOnBelt',
      group: 0,
      handler: handleActions
    },
    {
      id: 'customAction',
      text: 'custom Action',
      group: 0,
      handler: handleActions
    },
    {
      id: 'intrusion',
      text: 'intrusion',
      group: 0,
      handler: handleActions
    },
    {
      id: 'myTest2',
      text: 'test 2',
      group: 0,
      handler: handleActions
    }
  ]
  let welcomeMsg = 'WelcomeScanBoardingPass'
  let welcomeTitle = 'WelcomeTitleScanBoardingPass'
  if (manualScanMode) {
    welcomeMsg = config.isBoardingpassLookupEnable ? 'HandheldScanBTTitle' : 'WelcomePutBagOnBelt'
    welcomeTitle = config.isBoardingpassLookupEnable ? 'HandheldScanBTSubTitle' : 'WelcomeTitlePutBagOnBelt'
  } else {
    welcomeMsg = config.isBoardingpassLookupEnable ? 'WelcomeScanBoardingPass' : 'WelcomePutBagOnBelt'
    welcomeTitle = config.isBoardingpassLookupEnable ? 'WelcomeTitleScanBoardingPass' : 'WelcomeTitlePutBagOnBelt'
  }
  const animationLookup = isLandscape
    ? themeContext.AnimationLandscape.PlaceBag
    : themeContext.AnimationPortrait.PlaceBag
  const animationScanBp = isLandscape ? themeContext.AnimationLandscape.ScanBp : themeContext.AnimationPortrait.ScanBp
  const animationSize = useMedia(null, [
    { width: 1500, height: 500 },
    { width: 1200, height: 400 },
    { width: 1000, height: 600 },
    { width: 700, height: 450 }
  ])
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  const imageLocation = config.isBagtagLookupEnable
    ? `${themeContext.AnimationPath}/${sbdModel}/${animationLookup}`
    : `${themeContext.AnimationPath}/${sbdModel}/${animationScanBp}`
  const animationSection = (
    <Animation imageName={imageLocation} width={animationSize.width} height={animationSize.height} />
  )
  const textSection = (
    <>
      <PageTitle
        fontSize={
          themeContext.Welcome
            ? themeContext.Welcome.PageTitle.fontSize[ratioKey]
            : themeContext.PageTitle.fontSize[ratioKey]
        }
        fontWeight={
          themeContext.Welcome ? themeContext.Welcome.PageTitle.fontWeight : themeContext.PageTitle.fontWeight
        }
        color={themeContext.Welcome ? themeContext.Welcome.PageTitle.color : themeContext.PageTitle.color}
        alignItems="center"
      >
        {intl.formatMessage(messages[welcomeTitle])}
      </PageTitle>
      <PageSubTitle
        fontSize={
          themeContext.Welcome
            ? themeContext.Welcome.PageSubTitle.fontSize[ratioKey]
            : themeContext.PageSubTitle.fontSize[ratioKey]
        }
        fontWeight={
          themeContext.Welcome ? themeContext.Welcome.PageSubTitle.fontWeight : themeContext.PageSubTitle.fontWeight
        }
      >
        {intl.formatMessage(messages[welcomeMsg])}
      </PageSubTitle>
    </>
  )

  const header = null

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isBackRequired={false}
      isSkipRequired={false}
      isConfirmRequired={false}
      isLangRequired={true}
      displayItinerary={displayItinerary}
      atcAreaCollaps={themeContext.Footer.atcAreaCollaps}
      testData={testData}
    />
  )

  const popupSection = (
    <>
      {popupContainer.retryMediaAccess}
      {passportDataPopup}
    </>
  )
  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupContainer: popupSection },
    { contentWidth: contentWidth }
  )

  return <>{UIDisplay}</>
}

export default Welcome
