import { identifyService } from 'actions/etsTransactions/identifyService'
import { DynamicImage, PageHeader, PageSubTitle, PageTitle } from 'components/styledComponents'
import { FunctionCodes, ServiceNames, TOTAL_WEIGHT_UNIT } from 'constants/Constants'
import useUIBase from 'hooks/ui/useUIBase'
import usePopup from 'hooks/usePopup'
import { getBuildAccessibility, playSound } from 'main'
import React, { useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { getButtonDisplayConfig } from 'utils/getButtonDisplayConfig'
import { getScreenId } from 'utils/helper'
import { Footer } from '../footer'

const ConfirmTotalWeight = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const themeContext = useContext(ThemeContext)
  const appMode = useSelector((state) => state.kioskInfo.PSAM)
  const locale = useSelector((state) => state.localData.locale)
  // const totalWeightLimit = useSelector((state) => state.localData.totalWeightLimit)
  const totalWeightLimit = { totalWeightLimit: 0, unit: 'WEIGHT_KG' }
  const exceedLimitPopupRef = useRef(null)
  const exceedLimitPopupProps = {
    popupRef: exceedLimitPopupRef,
    contentText: intl.formatMessage(messages.ConfirmTotalWeightQuestion, {
      weight: totalWeightLimit.totalWeightLimit + ' ' + TOTAL_WEIGHT_UNIT[totalWeightLimit.unit]
    }),
    buttons: [
      { id: 'btnYes', text: intl.formatMessage(messages.buttonYes) },
      { id: 'btnNo', text: intl.formatMessage(messages.buttonNo) }
    ]
  }
  const [exceedLimitPopup, showExceedLimitPopup] = usePopup(exceedLimitPopupProps)
  const title = intl.formatMessage(messages.TotalWeightTitle)
  const subtitle = intl.formatMessage(messages.TotalWeightQuestion, {
    weight: totalWeightLimit.totalWeightLimit + ' ' + TOTAL_WEIGHT_UNIT[totalWeightLimit.unit]
  })

  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  const buildAccessibility = getBuildAccessibility()
  const screenLocation = useLocation()
  const screenId = getScreenId(screenLocation.pathname)

  useEffect(() => {
    handleAccessibility()
  }, [locale])

  const isQuitRequired = !appMode
  const handleAccessibility = () => {
    const accDef = {
      pathName: 'ConfirmTotalWeight',
      startIndex: 0,
      ...getButtonDisplayConfig({
        themeContext,
        isQuitRequired,
        screenId
      }),
      sequenceDef: {
        sequence: [
          { id: 'page-content', textId: 'OneDynamicTextWithDownKey', textParameters: [[title, subtitle].join('. ')] },
          { id: 'confirmBtn', text: intl.formatMessage(messages.buttonConfirm), buttonId: 'confirmBtn' },
          { id: 'cancelBtn', text: intl.formatMessage(messages.buttonDecline), buttonId: 'cancelBtn' }
        ]
      }
    }

    buildAccessibility(accDef)
  }
  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

  const location = `baggage.svg`
  const animationSection = (
    <DynamicImage imageName={location} cssName={'emb_animation_drawbox'} width={450} height={400} />
  )

  const textSection = (
    <>
      <PageTitle alignItems="center" justifyContent="center" color={themeContext.PrimaryFontColor}>
        {title}
      </PageTitle>
      <PageSubTitle justifyContent="center" color={themeContext.PrimaryFontColor}>
        {subtitle}
      </PageSubTitle>
    </>
  )

  const header = <PageHeader>{intl.formatMessage(messages.TotalWeightHeader)}</PageHeader>

  const footer = (
    <Footer
      isQuitRequired={isQuitRequired}
      quitBtnText={intl.formatMessage(messages.buttonQuit)}
      isCancelRequired={true}
      isLangRequired={true}
      cancelBtnText={intl.formatMessage(messages.buttonDecline)}
      cancelAction={() => {
        playSound.beepOK()
        showExceedLimitPopup()
      }}
      isSkipRequired={false}
      isConfirmRequired={true}
      confirmAction={() => {
        playSound.beepOK()
        dispatch(identifyService(FunctionCodes.COMPLETED, ServiceNames.SBD_CONFIRM_WEIGHT_LIMIT))
      }}
    />
  )

  const popupSection = <>{exceedLimitPopup()}</>

  let contentWidth = { landscapeMode: '50%', portraitMode: '90%' }

  const { UIDisplay } = useUIBase(
    { topSection: textSection, bottomSection: animationSection, footer, popupSection },
    {
      contentWidth: contentWidth
    }
  )

  return <>{UIDisplay}</>
}

export default ConfirmTotalWeight
