import React, { useState, Fragment, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { IconButton } from 'components'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'
const CancelAct = (props) => {
  const intl = useIntl()

  const themeContext = useContext(ThemeContext)

  return (
    <>
      <IconButton
        id={'cancelBtn'}
        cssName={props.cancelBtnStyle}
        color={themeContext.CancelButton.color}
        bgColor={themeContext.CancelButton.bgColor}
        width={themeContext.CancelButton.width}
        height={themeContext.CancelButton.height}
        disable={props.disableAction}
        onClick={props.cancelAction || null}
        fontSize={20}
        iconLocation={'LEFT'}
        isLeftBtn={true}
        text={props.cancelBtnText}
        icon={'cancel-icon.svg'}
        iconColor={themeContext.CancelButton.iconColor}
      />
    </>
  )
}
CancelAct.propTypes = {
  cancelBtnStyle: PropTypes.string,
  cancelAction: PropTypes.func
}

export default CancelAct
