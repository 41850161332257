import { fetchWithTimeout, handleFetchErrors, goToFetchGenericError } from 'utils/FetchWithTimeout'
import { updateError } from 'actions/commonActions'
import { updateSessions } from 'actions/sessionActions'
import { updateResponses } from 'actions/responseActions'
import { updateLocalData, setBagProcessStatus } from 'actions/localActions'
import { updateCustomData } from 'custom/actions/customActions'
import { updateSettings } from 'actions/settingActions'
import { getItinerarySBD } from 'actions/etsTransactions/getItinerarySBD'
import { ETS_TXN_STATUS, END_TXN_REASON, OOS } from 'constants/Constants'
import { ErrCodes } from 'constants/Errors'
import { isEmpty, navigate } from 'utils/helper'
import { configsToMap, replacer, getSubstringForLog, updateConfigFromString } from 'utils/helper'
import { formatTSDdescription, sendAppLog, formatTSDEvents, adjustEventDateTimes } from 'utils/appTransactions'
import {
  TraceLevels,
  TSDTypes,
  transactionStateEvent,
  AppStateEventType,
  HostInterfaceEventType,
  SessionInfoEventType
} from 'embross-device-manager'
import { appLog, logEvent } from 'utils/Logger'
import { getTSDManager, getDeviceManager, store, history, getEventLogger, getSummaryStore, getAccessibilityManager } from 'main'
import { EventTypeName } from 'constants/Constants'
import { v4 as uuidv4 } from 'uuid'
import { logEvents, sessionEnd, EventFlows, EventFunctions } from 'utils/appEventLogger'

function buildStartTxn(store, clientType, appVer, searchObj, sessionId) {
  let mediaData
  if (searchObj && searchObj.barcodeData) {
    mediaData = searchObj.barcodeData
  } else {
    mediaData = null
  }
  return {
    clientDetail: {
      location: store.getState().kioskInfo.airportCode,
      clientID: store.getState().kioskInfo.kioskId,
      clientTime: new Date(),
      kmClientID: store.getState().kioskInfo.smKioskId,
      selectedLanguage: store.getState().localData.locale.toUpperCase(),
      clientType: clientType,
      appVersion: appVer,
      carrierCode: null
    },
    mediaData: mediaData,
    sessionId: sessionId
  }
}

//searchObj is added when ETS transaction is not started yet - after successful transaction start call getItinerarySBD
export function startEtsTransaction(store, clientType, appVer, searchObj, sessionId = null) {
  appLog(TraceLevels.LOG_EXT_TRACE, '==> ETS request start transaction begins ... ...')
  store.dispatch(updateLocalData('activatedBagtags', ''))
  store.dispatch(updateLocalData('BagsDetail', null))
  store.dispatch(updateSessions('updateEtsTxnStatus', ETS_TXN_STATUS.ETS_TXN_STARTED))
  if (searchObj != null && searchObj != undefined) {
    appLog(TraceLevels.LOG_TRACE, '>> ETS Start Transaction searchObj: ' + JSON.stringify(searchObj, replacer))
    //	appLog(TraceLevels.LOG_EXT_TRACE,'>> ETS Start Transaction startEtsTransaction(..) - Dispatch --> "Please Wait" screen ...')
    store.dispatch(updateLocalData('appFlow', 1))
    const skipFailRequest = searchObj['skipFailRequest']
    if (!skipFailRequest) {
      navigate({ pathname: 'pleaseWait', state: { messageId: 'PleaseWaitFindReservation' } })
    }
  }
  const request = buildStartTxn(store, clientType, appVer, searchObj, sessionId)
  appLog(TraceLevels.LOG_TRACE, '>> ETS Start Transaction - request = ' + JSON.stringify(request, replacer))
  //return dispatch => {
  return fetchWithTimeout('startTxn/', request)
    .then((json) => {
      //      appLog(TraceLevels.LOG_EXT_TRACE,'ETS Start Transaction response ... ')
      appLog(TraceLevels.LOG_TRACE, '>> ETS Start Transaction - response = ' + JSON.stringify(json, replacer))
      // check for errors
      if (json.etsResponse) {
        //start TSD events for transaction
        const summaryStore = getSummaryStore()
        const eventLogger = getEventLogger()
        if (eventLogger && !eventLogger.clientSessionId) {
          eventLogger.ClientSessionId = uuidv4()
        }
        let startTime = new Date().getTime()
        store.dispatch(updateLocalData('updateServerTime', json.etsResponse.serverDetail.currentDateTime))
        store.dispatch(
          updateLocalData(
            'updateClientBaseTime',
            json.etsResponse.clientTime - json.etsResponse.serverDetail.currentDateTime
          )
        )
        store.dispatch(updateLocalData('updateStartTransactionTime', startTime))
        store.dispatch(setBagProcessStatus(0, false))
        summaryStore.StartTime = startTime
        getTSDManager().addAppStateEvent(AppStateEventType.TXN_START, json.etsResponse.sessionInfo.etsSessionID)
        logEvents(
          EventFlows.BagDrop,
          EventFunctions.ETSTransactionStart,
          'ETSTransactionID: ' + json.etsResponse.sessionInfo.etsSessionID
        )
        store.dispatch(updateSessions('updateEtsTxnStatus', ETS_TXN_STATUS.ETS_TXN_OK))
        store.dispatch(updateLocalData('appFlow', 1))
        // update new session info
        appLog(TraceLevels.LOG_EXT_TRACE, 'update new session info ...')
        store.dispatch(updateSessions('updateSessionInfo', json.etsResponse.sessionInfo))
        summaryStore.TransactionID = json.etsResponse.sessionInfo.etsSessionID
        //store.dispatch(updateSessions('updateSessionConfig', json.etsResponse.configElements))
        //convert array of objects to map
        store.dispatch(updateSessions('updateSessionConfig', configsToMap(json.etsResponse.configElements)))

        // if OOS is true then update error and goto error page

        store.dispatch(updateSessions('updateServiceName'), store.getState().settings.firstTransition)
        store.dispatch(updateLocalData('active', true))
        //store.dispatch(updateSettings('updateServerTimeString', json.etsResponse.clientTimeInString))
        if (config.enableETSconfig) {
          saveSessionConfigInServiceConfiguration(store)
          updateConfigsFromETS(store)
        }
        /* if (config.isCUSSRequired === false) {
          store.dispatch(updateSessions('updatePSAM', true))
        } */
        appLog(TraceLevels.LOG_TRACE, 'test log level: ' + TraceLevels.LOG_TRACE)
        appLog(TraceLevels.LOG_EXT_TRACE, 'test log level: ' + TraceLevels.LOG_EXT_TRACE)

        let location = {
          state: {
            from: config.firstScreen,
            statistics: {
              paxOrdinal: null,
              paxName: null
            }
          }
        }

        // PSAM mode startEtsTxn and getItinerarySBD combined
        if (searchObj !== null && searchObj !== undefined) {
          appLog(TraceLevels.LOG_EXT_TRACE, '>> searchObj2: ' + JSON.stringify(searchObj, replacer))
          location = Object.assign({}, location, {
            state: Object.assign({}, location.state, {
              maxAttemptsReached: false
            })
          })
          store.dispatch(getItinerarySBD(searchObj, location))
        }
        // startEtsTxn response was delayed (button pushed) or bardcodeDamaged
        else if (store.getState().localData.startTxnNextScreen !== '') {
          let nextScreen = store.getState().localData.startTxnNextScreen
          appLog(TraceLevels.LOG_TRACE, 'localData.startTxnNextScreen: ' + nextScreen)
          location = Object.assign({}, location, {
            state: Object.assign({}, location.state, {
              data: null
            })
          })

          if (nextScreen === 'retry') {
            location = Object.assign({}, location, {
              state: Object.assign({}, location.state, {
                maxAttemptsReached: false
              })
            })
            store.dispatch(updateError(ErrCodes.BARCODE_UNREADABLE, 'startTxn', config.firstScreen))
          }

          let url = Object.assign({}, location, { pathname: nextScreen })
          store.dispatch(updateLocalData('startTxnNextScreen', ''))
          appLog(TraceLevels.LOG_TRACE, 'url: ' + JSON.stringify(url, replacer))
          navigate(url)
        }
      } else {
        store.dispatch(updateSessions('updateEtsTxnStatus', ETS_TXN_STATUS.ETS_TXN_FAILED))
        if (json.error) {
          goToFetchGenericError('startEtsTransaction', json, store.dispatch)
        }
      }
    })
    .catch((err) => {
      appLog(TraceLevels.LOG_ALERT, 'Catch:' + err)
      appLog(TraceLevels.LOG_ALERT, 'Catch err.details:' + err.detail)
      store.dispatch(updateSessions('updateEtsTxnStatus', ETS_TXN_STATUS.ETS_TXN_FAILED))
      // navigate to the Error page - set the error code and message before
      handleFetchErrors(err, 'startEtsTransaction()', 'error')
    })
  //}
}

function transactionEventGenerate(sessionId, event) {
  const transactionStateEvent = {
    screenFlowEvent: null,
    deviceInteractionEvent: null,
    businessFlowEvent: null,
    screenInteractionEvent: null,
    appStateEvent: null,
    hostInterfaceEvent: null,
    sessionSummaryEvent: null
  }
  const { stateData } = event
  let obj
  switch (event.eventType) {
    case EventTypeName.ENVIRONMENT_DETAILS:
      break
    case EventTypeName.SESSION_SUMMARY_DETAILS:
      obj = {
        eventID: stateData.eventID,
        sessionID: sessionId,
        eventTime: event.eventTime,
        description: stateData.description
      }

      transactionStateEvent.sessionSummaryEvent = obj
      break
    case EventTypeName.SCREEN_INTERACTION_EVENT_DETAILS:
      obj = {
        eventID: stateData.eventID,
        sessionID: sessionId,
        eventTime: event.eventTime,
        screenID: stateData.screenID,
        elementID: stateData.elementID,
        xPos: stateData.x,
        yPos: stateData.y,
        description: stateData.description
      }

      transactionStateEvent.screenInteractionEvent = obj
      break
    case EventTypeName.SCREEN_FLOW_EVENT_DETAILS:
      obj = {
        eventID: stateData.eventID,
        eventTime: event.eventTime,
        sessionID: sessionId,
        screenID: stateData.screenID,
        transitionCode: stateData.transitionCode,
        travelerID: null,
        travelerName: null,
        errorCode: null,
        targetScreenID: stateData.targetScreenID,
        description: null
      }

      if (stateData.description != null) {
        if (stateData.description.includes('ErrorCode=')) {
          obj.errorCdoe = stateData.description
        } else {
          obj.travelerID = stateData.description
        }
      }

      transactionStateEvent.screenFlowEvent = obj
      break
    case EventTypeName.DEVICE_INTERACTION_EVENT_DETAILS:
      obj = {
        eventID: stateData.eventID,
        sessionID: sessionId,
        eventTime: event.eventTime,
        screenID: stateData.deviceScreenID,
        deviceName: stateData.device.name,
        documentType: stateData.device.type,
        travelerID: null,
        travelerName: null,
        description: stateData.description
      }

      transactionStateEvent.deviceInteractionEvent = obj
      break
    case EventTypeName.APP_STATE_EVENT_DETAILS:
      obj = {
        eventID: stateData.eventID,
        eventTime: event.eventTime,
        sessionID: sessionId,
        description: stateData.description
      }

      transactionStateEvent.appStateEvent = obj
      break
    case EventTypeName.HOST_INTERFACE_EVENT_DETAILS:
      obj = {
        eventID: stateData.eventID,
        eventTime: event.eventTime,
        sessionID: sessionId,
        endpointAddress: stateData.endpointAddress,
        currentServiceName: stateData.serviceName,
        description: stateData.message
      }

      transactionStateEvent.hostInterfaceEvent = obj
      break
    case EventTypeName.BUSINESS_FLOW_EVENT_DETAILS:
    default:
  }
  return transactionStateEvent
}

function buildEndTxn(sessionInfo, reason, code, kioskId, airportCode, events) {
  let endTxnObj = {
    sessionInfo: {
      etsSessionID: sessionInfo.etsSessionID,
      emhaSessionID: sessionInfo.emhaSessionID
    },
    jsonExtend: null,
    carrierCode: null,
    currentClientTime: 0,
    endTxnReason: reason,
    errorCode: code
  }
  // build txnStateLogs
  if (config.sendStats && Array.isArray(events)) {
    let txnStateLogs = []
    let eventsListString = ''

    /* Transfer all the event logs into a special obj type that match the ets model and sent the events to data collector */
    // events.forEach( event => {
    //   if (config.statsVersion === 3) {
    //     let obj = {
    //       eventSource: kioskId,
    //       transactionStateEvent: transactionEventGenerate(sessionInfo.etsSessionID, event),
    //     }
    //     txnStateLogs.push(obj)
    //   }
    // })

    if (config.statsVersion === 3) {
      eventsListString = JSON.stringify(events)
    }

    // for (let i = 0; i < events.length; i++) {
    //   let tse = transactionStateEvent[events[i].tsdType]
    //   delete events[i].tsdType
    //   let obj = {
    //     eventSource: kioskId,
    //     transactionStateEvent: { [tse]: events[i] },
    //     syncToExternal: true
    //   }
    //   txnStateLogs.push(obj)
    // }
    if (txnStateLogs.length !== 0) {
      endTxnObj.txnStateLogs = txnStateLogs
    }
    if (eventsListString !== '') {
      endTxnObj.txnStateData = eventsListString
    }
  }

  /*
  if (config.sendStats && Array.isArray(events)) {
  	let txnStateLogs = []
  	  	for (let i = 0; i < events.length; i++) {
      let obj = Object.assign({}, events[i], {eventSource: kioskId, locationID: airportCode})
      txnStateLogs.push(obj)
  	}
  	appLog(TraceLevels.LOG_EXT_TRACE,'buildEndTxn: '+JSON.stringify(txnStateLogs, replacer))
  	//endTxnObj.txnStateLogs = txnStateLogs
  }
  */
  //appLog(TraceLevels.LOG_EXT_TRACE,'buildEndTxn: '+JSON.stringify(endTxnObj, replacer))
  return endTxnObj
}

export function endEtsTransaction(store, reason, code, reasonCode) {
  // const acuantClient = getAcuantClient()
  const summaryStore = getSummaryStore()
  appLog(TraceLevels.LOG_TRACE, '==> ETS end transaction begins, reason: ' + reason + ' code: ' + code)
  let sessionInfo = store.getState().sessions.sessionInfo
  let kioskId = store.getState().kioskInfo.kioskId
  let airportCode = store.getState().kioskInfo.airportCode
  if (isEmpty(sessionInfo)) {
    appLog(TraceLevels.LOG_TRACE, 'ETS end transaction - sessionInfo is empty - skip it.')
    return
  }
  const lang = store.getState().localData.locale.toUpperCase()
  const endTime = new Date().getTime()
  if (
    store.getState().responses &&
    store.getState().responses.itineraryInfo &&
    store.getState().responses.itineraryInfo.scanSequence
  ) {
    summaryStore.Numofpax = store.getState().responses.itineraryInfo.scanSequence.length
  } else {
    summaryStore.Numofpax = 0
  }

  summaryStore.EndTime = endTime
  summaryStore.Endtxncode = reasonCode ? reasonCode : END_TXN_REASON.COMPLETE_OK
  const SBDSummary = summaryStore.generateSummary()

  getTSDManager().addAppStateEvent(AppStateEventType.TXN_END)

  // 2022-11-16 old summary data
  //const summaryDetail = formatTSDdescription(reason, reasonCode)

  getTSDManager().addSessionInfoEvent(SessionInfoEventType.SBD_SUMMARY, lang, SBDSummary)

  sessionEnd()

  // test get all events
  const orgEvents = getTSDManager().getEvents()
  console.log('Orignal EVENTS:::', orgEvents, orgEvents.length)
  const events = orgEvents//adjustEventDateTimes(orgEvents)
  /* const eventLogger = getEventLogger()
  const eventLogs = eventLogger.eventLogs
  const events = tsdEvents.concat(eventLogs) */
  console.log('ALL EVENTS:::', events, events.length)
  if (Array.isArray(events)) {
    //	  for (let i = 0; i < events.length; i++) {
    //	    appLog(TraceLevels.LOG_EXT_TRACE, JSON.stringify(events[i], replacer))
    //	  }
  }

  // format Events
  //let formatEvents = formatTSDEvents(kioskId, airportCode, events)

  //formatEvents.forEach((event) => {
  events.forEach((event) => { 
    if (event.eventType === EventTypeName.SESSION_SUMMARY_DETAILS) {
      sendAppLog('0611', JSON.stringify(event.stateData))
    }
  })

  // acuantClient.close()
  // const faceTracking = getDeviceManager().getDevice(deviceIds.FACE_TRACKING)
  // faceTracking.disable()
  // faceTracking.OnDeviceEvent = null

  // reset when transaction end
  summaryStore.resetStore()

  return fetchWithTimeout('endTxn/', buildEndTxn(sessionInfo, reason, code, kioskId, airportCode, events))
    .then((json) => {
      //appLog(TraceLevels.LOG_EXT_TRACE,'ETS End Transaction response ... ')
      appLog(TraceLevels.LOG_TRACE, '>> ETS End Transaction - response = ' + JSON.stringify(json, replacer))
      store.dispatch(updateLocalData('active', false))
      store.dispatch(updateLocalData('updateCarrierCode', null))
      if (!config.isCUSSRequired) {
        navigate(config.firstScreen)
      }
    })
    .catch((err) => {
      if (err.errorCode !== ErrCodes.DIFFERENT_SESSION) {
        appLog(TraceLevels.LOG_ALERT, 'ETS End Transaction Catch:' + err)
        appLog(TraceLevels.LOG_ALERT, 'ETS End Transaction Catch err.details:' + err.detail)
        // skip errors as response does not contain any important information
        navigate(config.firstScreen)
      }
    })
}

export function initialStore(dispatch) {
  appLog(TraceLevels.LOG_TRACE, 'initialStore ... ')
  dispatch(updateSessions('initialSessions'))
  //should not reset local data here. should be in end transaction
  dispatch(updateLocalData('initialLocalData'))
  dispatch(updateResponses('initialResponses'))
  dispatch(updateCustomData('initialCustomData'))
  dispatch(updateError(null))

  
  /**::::::::::::::::::::::::::::::::::::: Accessibility :::::::::::::::::::::::::::::::::::::::: */
  // when redux store reset, accessibility manager should reset the language to default locale
  const accMgr = getAccessibilityManager()
  if (accMgr) {
    accMgr.switchLocale(config.defaultLanguage)
  }

  /**::::::::::::::::::::::::::::::::::: EOF Accessibility :::::::::::::::::::::::::::::::::::::: */

}

export function clearStore(dispatch) {
  appLog(TraceLevels.LOG_TRACE, 'clearStore ... ')
  dispatch(updateSessions('clearSessions'))
  dispatch(updateLocalData('clearLocalData'))
  dispatch(updateResponses('clearResponses'))
  dispatch(updateCustomData('clearCustomData'))
  dispatch(updateError(null))
}

// save predefined session parameters in the service configuration
function saveSessionConfigInServiceConfiguration(store) {
  try {
    let conf = store.getState().settings.serviceConfiguration
    let change = false
    for (let i = 0; i < config.sessionToSettings.length; i++) {
      let paramName = config.sessionToSettings[i]
      let configElement = store.getState().sessions.configElements[paramName]
      if (conf[paramName] && configElement && conf[paramName] !== configElement) {
        conf[paramName] = configElement
        change = true
      }
    }
    if (change) {
      store.dispatch(updateSettings('updateServiceConfiguration', conf))
    }
  } catch (error) {
    appLog(TraceLevels.LOG_ALERT, 'saveSessionConfigInServiceConfiguration Catch:' + error)
  }
}

//overwrite default configuration from ETS start
function updateConfigsFromETS(store) {
  try {
    let configInfo = store.getState().sessions.configElements
    Object.keys(configInfo).forEach((key) => {
      //if (config.hasOwnProperty(key)) {
        updateConfigFromString(key, configInfo[key])
        if (config[key] != configInfo[key]) {
          appLog(TraceLevels.LOG_TRACE, 'updateConfigsFromETS: ' + key + ' : ' + config[key] + ' => ' + configInfo[key])
        }
      //}
      // update device manager log level
      if (key === 'maxTraceLevelToSend') {
        getDeviceManager().setMaxTraceLevel(config[key])
      }
    })
  } catch (error) {
    appLog(TraceLevels.LOG_ALERT, 'updateConfigsFromETS Catch:' + error)
  }
}
