import { ItineraryTitle, PageContent, PageSubContent, PageText } from 'components/styledComponents'
import useMedia from 'hooks/useMedia'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { populateItineraryInfo } from 'utils/helper'
import './ItineraryInfo.scss'

const ItineraryInfo = (props) => {
  const intl = useIntl()
  const themeContext = useContext(ThemeContext)
  const { formatMessage } = intl
  const itineraryInfo = useSelector((state) => state.responses.itineraryInfo)
  const bagWeight = useSelector((state) => state.localData.bagWeight)
  const bagWeightUnit = useSelector((state) => state.localData.bagWeightUnit)
  const [itineraryData, setItineraryData] = useState(null)
  const ratioKey = useMedia(null, ['landscape1920', 'landscape1280', 'portrait1080', 'portrait768'], '')

  useEffect(() => {
    setItineraryData(itineraryInfo ? populateItineraryInfo(itineraryInfo) : null)
  }, [itineraryInfo])

  const bagAllowance = (
    <div className="bag-info-wrap">
      <div className="bag-info-number">0</div>
      <div className="bag-info-number">KG</div>
    </div>
  )

  //Manually compare current bag weight to unit limit weight
  const showRedText = () => {
    if (bagWeight !== '0' && bagWeightUnit === 'KG') {
      if (+bagWeight > 23) {
        return 'red'
      } else {
        return ''
      }
    } else if (bagWeight !== '0' && bagWeightUnit === 'LB') {
      if (+bagWeight > 50.7) {
        return 'red'
      } else {
        return ''
      }
    }
  }

  const customBagWeightUnit = config.customBagWeightUnit ? config.customBagWeightUnit : bagWeightUnit

  let displayName = ''
  let flightInfo = ''
  let allowance = ''
  let flightCode = ''
  if (itineraryData) {
    displayName =
      itineraryData.groupSize > 1
        ? formatMessage(messages.itineraryInfoName, {
            name: itineraryData.displayName,
            groupSize: itineraryData.groupSize
          })
        : itineraryData.displayName
    flightCode = itineraryData.airlineCode + itineraryData.flightNumber
    switch (config.showFlightInfo) {
      case 1:
        flightInfo = formatMessage(messages.itineraryInfo1, {
          flightCode: flightCode
        })
        break
      case 2:
        flightInfo = itineraryData.departureTime
          ? formatMessage(messages.itineraryInfo2, {
              flightCode: flightCode,
              departureTime: itineraryData.departureTime
            })
          : formatMessage(messages.itineraryInfo1, { flightCode: flightCode })
        break
      case 3:
        flightInfo = itineraryData.departureTime
          ? formatMessage(messages.itineraryInfo3, {
              flightCode: flightCode,
              departureCity: itineraryData.departureCity,
              arrivalCity: itineraryData.arrivalCity,
              departureTime: itineraryData.departureTime
            })
          : formatMessage(messages.itineraryInfo4, {
              flightCode: flightCode,
              departureCity: itineraryData.departureCity,
              arrivalCity: itineraryData.arrivalCity
            })
        break
      case 4:
        flightInfo = formatMessage(messages.itineraryInfo4, {
          flightCode: flightCode,
          departureCity: itineraryData.departureCity,
          arrivalCity: itineraryData.arrivalCity
        })
        break
      default:
        flightInfo = flightCode
        break
    }
    if (config.showAllowance && itineraryData.allowance && itineraryData.allowance.unit) {
      if (itineraryData.allowance.unit == 'PC') {
        if (itineraryData.allowance.value == 1) {
          allowance = formatMessage(messages.allowanceInfo1)
        } else {
          allowance = formatMessage(messages.allowanceInfo2, { count: itineraryData.allowance.value })
        }
      } else {
        allowance = formatMessage(messages.allowanceInfo3, { weight: itineraryData.allowance.value })
      }
    }

    if (config.showFlightInfo > 4) {
      let boxAttribute = {}
      if (props.boxHeight) {
        boxAttribute['style'] = { height: props.boxHeight }
      }
      
      return (
        <PageContent
          id="itineraryInfo-content"
          tabIndex="0"
          flexFlow={'row'}
          justifyContent={themeContext.Itinerary.containerJustifyContent[ratioKey]}
          height={themeContext.Itinerary.containerHeight[ratioKey]}
          padding={themeContext.Itinerary.containerPadding[ratioKey]}
        >
          <PageSubContent
            justifyContent={themeContext.Itinerary?.contentJustifyContent?.[ratioKey] ?? 'space-between'}
            height="100%"
            padding={themeContext.Itinerary.paxInfoPadding[ratioKey]}
          >
            {themeContext.Itinerary.showPaxName ? (
              <PageText
                justifyContent={'flex-start'}
                fontSize={themeContext.Itinerary.paxNameFontSize[ratioKey]}
                fontWeight={themeContext.Itinerary.paxNameFontWeight}
                color={themeContext.Itinerary.primaryFontColor}
              >
                {displayName}
              </PageText>
            ) : (
              <div />
            )}
            {themeContext.Itinerary.showFlight ? (
              <PageText
                justifyContent={'flex-start'}
                fontSize={themeContext.Itinerary.flightFontSize[ratioKey]}
                fontWeight={themeContext.Itinerary.flightFontWeight}
                color={themeContext.Itinerary.primaryFontColor}
              >
                {itineraryData.departureCityCode} - {itineraryData.arrivalCityCode}
              </PageText>
            ) : (
              <div />
            )}
          </PageSubContent>
          {clientConfig.disableItineraryWeightDisplay ? (
            <div />
          ) : (
            <PageSubContent
              justifyContent={themeContext.Itinerary?.contentJustifyContent?.[ratioKey] ?? 'space-between'}
              height="100%"
              padding={'0'}
            >
              <PageText
                fontSize={themeContext.Itinerary.bagWeightFontSize[ratioKey]}
                color={themeContext.Itinerary.primaryFontColor}
                style={{ color: showRedText() }}
              >
                {`${bagWeight} ${customBagWeightUnit}`}
              </PageText>
              <PageText
                fontSize={themeContext.Itinerary.bagWeightTitleFontSize[ratioKey]}
                color={themeContext.Itinerary.secondaryFontColor}
              >
                {formatMessage(messages.BagWeight)}
              </PageText>
            </PageSubContent>
          )}
          {config.showAllowance ? (
            <PageSubContent
              padding={'0 20px'}
              style={{
                borderLeft: `1px ${themeContext.PrimaryFontColor} solid`
              }}
            >
              <PageText fontSize={'35px'} minHeight={'20px'}>
                {allowance ? allowance : '--'}
              </PageText>
              <PageText fontSize={'15px'} minHeight={'20px'}>
                {formatMessage(messages.BagAllowance)}
              </PageText>
            </PageSubContent>
          ) : (
            <div />
          )}
        </PageContent>
      )
    } else {
      return (
        <ItineraryTitle id={'itineraryInfo'} tabIndex={0}>
          <div className="page_title_paxName">{displayName}</div>
          <div className="page_title_itinerary">{flightInfo}</div>
          <div className="page_title_itinerary">{allowance}</div>
        </ItineraryTitle>
      )
    }
  } else {
    return (
      <PageContent
        id="itineraryInfo-content"
        tabIndex="0"
        flexFlow={'row'}
        justifyContent={themeContext.Itinerary.containerJustifyContent[ratioKey]}
        height={themeContext.Itinerary.containerHeight[ratioKey]}
        padding={themeContext.Itinerary.containerPadding[ratioKey]}
      >
        {clientConfig.disableItineraryWeightDisplay ? (
          <div />
        ) : (
          <PageSubContent
            justifyContent={themeContext.Itinerary?.contentJustifyContent?.[ratioKey] ?? 'space-between'}
            height="100%"
            padding={'0'}
          >
            <PageText
              fontSize={themeContext.Itinerary.bagWeightFontSize[ratioKey]}
              color={themeContext.Itinerary.primaryFontColor}
              style={{ color: showRedText() }}
            >
              {`${bagWeight} ${customBagWeightUnit}`}
            </PageText>
            <PageText
              fontSize={themeContext.Itinerary.bagWeightTitleFontSize[ratioKey]}
              color={themeContext.Itinerary.secondaryFontColor}
            >
              {formatMessage(messages.BagWeight)}
            </PageText>
          </PageSubContent>
        )}
      </PageContent>
    )
  }
}

ItineraryInfo.propTypes = {
  iconSize: PropTypes.number,
  boxWidth: PropTypes.string,
  boxHeight: PropTypes.string,
  showCityName: PropTypes.bool,
  hAlign: PropTypes.oneOf(['center', 'flex-start', 'flex-end'])
}

export default ItineraryInfo
