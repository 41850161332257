import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  CustomPage,
  Welcome,
  PassengerSelection,
  GenericErrors,
  ErrorMismatch,
  PutBagOnBelt,
  Intrusion,
  OverweightAgentScan,
  VerifyPayment,
  VerifyDocuments,
  AgentScan,
  PleaseWait,
  AgentScanBio,
  TVSTakePhoto,
  ScanDocuments,
  AnalyzePhoto
} from 'custom/layouts'

export const customRoutes = [
  {
    element: <CustomPage />,
    path: `${PUBLIC_URL}/customPage`
  },
  {
    element: <PassengerSelection />,
    path: `${PUBLIC_URL}/PassengerSelection`
  },
  {
    element: <GenericErrors />,
    path: `${PUBLIC_URL}/Error`
  },
  {
    element: <ErrorMismatch />,
    path: `${PUBLIC_URL}/ErrorMismatch`
  },
  {
    element: <Welcome />,
    path: `${PUBLIC_URL}/Welcome`
  },
  {
    element: <PutBagOnBelt />,
    path: `${PUBLIC_URL}/PutBagOnBelt`
  },
  {
    element: <Intrusion />,
    path: `${PUBLIC_URL}/Intrusion`
  },
  {
    element: <OverweightAgentScan />,
    path: `${PUBLIC_URL}/OverweightAgentScan`
  },
  {
    element: <VerifyPayment />,
    path: `${PUBLIC_URL}/VerifyPayment`
  },
  {
    element: <VerifyDocuments />,
    path: `${PUBLIC_URL}/VerifyDocuments`
  },
  {
    element: <AgentScan />,
    path: `${PUBLIC_URL}/AgentScan`
  },
  {
    element: <PleaseWait />,
    path: `${PUBLIC_URL}/PleaseWait`
  },
  {
    element: <AgentScanBio />,
    path: `${PUBLIC_URL}/AgentScanBio`
  },
  {
    element: <TVSTakePhoto />,
    path: `${PUBLIC_URL}/TVSTakePhoto`
  },
  {
    element: <ScanDocuments />,
    path: `${PUBLIC_URL}/ScanDocuments`
  },
  {
    element: <AnalyzePhoto />,
    path: `${PUBLIC_URL}/AnalyzePhoto`
  }
]

/* export const customRoutes = () => {
  return (
    <Routes>
      <Route path={`${PUBLIC_URL}/customPage`} element={<CustomPage />} />
    </Routes>
  )
} */
